/**
 * 全局请求 URL地址
 */

import { environment } from '../../environments/environment';
export let access_token = environment.access_token;
export let mockUrl = environment.mockUrl;

export let domain = environment.domain;
export let baseUrl = `${domain}`;
export let uploadUrl = `${domain}/basics/v1/file`;
export let riskUrl = `${domain}/hazard/v1`;
export let uploadUrlAliyuncs = `https://oss-cn-beijing.aliyuncs.com?accessKey=LTAIzpfbqAanWh30&secretKey=w91asl9vXFUC0Xsf9cDI2MJ0DvXM41&bucketName=testxaloss`;
export let logUrl = `http://bugly.cn-beijing.log.aliyuncs.com/logstores/web_bug_log/track?APIVersion=0.6.0`;
export let MapUrl = environment.MapUrl;
export let BZbaseUrl = environment.BZbaseUrl;
export let YHbaseUrl = environment.YHbaseUrl;
export let XinbaseUrl = environment.XinbaseUrl;
