import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';

import { _HttpClient } from '@delon/theme';

import { baseUrl, riskUrl, uploadUrl, CommonService } from '@core';

import { Router, ActivatedRoute } from '@angular/router';
// import * as moment from 'moment';
import { format } from 'date-fns';
@Component({
  selector: 'app-risk',
  templateUrl: './risk.component.html',
  styleUrls: ['./risk.component.less'],
})
export class RiskComponent implements OnInit {
  window = window;
  riskUrl = riskUrl;

  /**
   * table
   */
  tableHeight = '';
  loading = false;
  aloading = false;
  searchParams: any = {
    objectType: 0,
  };
  rows = [];
  pageIndex = 1;
  total = 0;
  pageSize = 5;

  // 地区数据
  areaData = [];
  params: any = {};

  /**
   * 对象类型
   */
  evaluateTypes = [
    { label: '设备设施', value: 0 },
    { label: '作业活动', value: 1 },
    { label: '工艺节点', value: 2 },
    { label: '作业场所', value: 3 },
  ];

  /**
   * 风险等级
   */
  xycsEmRiskLevels = [
    { label: '重大风险', value: 1 },
    { label: '较大风险', value: 2 },
    { label: '一般风险', value: 3 },
    { label: '低风险', value: 4 },
  ];

  width = '';
  widthList = [];

  widthMap = {
    '0': '30+100+150+150+150+150+150+150+70+150+150+200+200+200+200+200+200+200+120+120+120+150+100',
    '1': '30+100+70+150+150+150+150+70+70+150+200+200+200+200+200+70+120+120+120+120+150+100+100+150',
    '2': '30+100+70+150+150+150+150+150+150+150+150+70+70+150+200+200+200+200+200+70+120+120+120+120+150+100+100+150',
    '3': '30+100+70+150+150+150+150+70+70+150+200+200+200+200+200+70+120+120+120+120+150+100+100+150',
  };
  constructor(
    private fb: FormBuilder,
    private message: NzMessageService,
    private http: _HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
    this.tableHeight = `${document.body.getBoundingClientRect().height - 195 - 60 - 60}px`;
  }

  ngOnInit() {
    this.getAreaData();
    this.search();
  }
  getWidth() {
    let widthList = this.widthMap[this.searchParams.objectType].split('+');
    return `${widthList.reduce((prev, current) => prev + +current, 0)}px`;
  }

  getWidthList() {
    let widthList = this.widthMap[this.searchParams.objectType].split('+');
    return widthList.map(w => `${w}px`);
  }
  // 获取地区选择
  getAreaData() {
    const params = {
      ...this.params,
    };
    this.aloading = true;
    this.http.get<any>(`${baseUrl}/hazard/v1/regulatoryscreens/riskRanking`, params).subscribe(res => {
      this.aloading = false;
      if (res.code === 0) {
        this.areaData = res.data.riskRanking;
        this.areaData = this.areaData.slice(1);
      } else if (res.code === 100) {
        this.router.navigateByUrl('/passport/login');
        return;
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }
  /**
   * 加载数据
   * @param reset 起始页
   */
  search(reset: boolean = false) {
    this.width = this.getWidth();
    this.widthList = this.getWidthList();

    if (reset) {
      this.pageIndex = 1;
    }
    if (this.searchParams.accTime && this.searchParams.accTime.length > 0) {
      this.searchParams.checkStart = format(this.searchParams.accTime[0], 'YYYY-MM-DD');
      this.searchParams.checkEnd = format(this.searchParams.accTime[1], 'YYYY-MM-DD');
    } else {
      this.searchParams.checkStart = '';
      this.searchParams.checkEnd = '';
    }
    if (this.searchParams.region === null) {
      this.searchParams.region = '';
    }
    if (this.searchParams.riskLevel === null) {
      this.searchParams.riskLevel = '';
    }
    const searchParams = {
      ...this.params,
      ...this.searchParams,
      page: this.pageIndex,
      limit: this.pageSize,
    };
    console.log(searchParams);
    Object.entries(searchParams).forEach(([key, value]) => {
      value === null && delete searchParams[key];
    });

    this.loading = true;
    this.http.get<any>(`${baseUrl}/hazard/v1/regulatoryscreens/popup/risk`, searchParams).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.rows = res.data.content || [];
        this.total = res.data.totalElements || 0;
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }
}
