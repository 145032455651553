import { SettingsService, _HttpClient } from '@delon/theme';
import { Component, OnDestroy, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { SocialService, SocialOpenType, ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { ReuseTabService } from '@delon/abc';
import { LazyService } from '@delon/util';
import { environment } from '@env/environment';
import { StartupService, baseUrl } from '@core';

declare var CryptoJS: any;

@Component({
  selector: 'passport-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  providers: [SocialService],
})
export class UserLoginComponent {
  form: FormGroup;
  errorMsg = '';
  loading = false;

  constructor(
    fb: FormBuilder,
    modalSrv: NzModalService,
    private router: Router,
    private settingsService: SettingsService,
    private socialService: SocialService,
    @Optional()
    @Inject(ReuseTabService)
    private reuseTabService: ReuseTabService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private startupSrv: StartupService,
    public http: _HttpClient,
    private lazy: LazyService,
    public msg: NzMessageService,
  ) {
    this.lazy.loadScript('assets/js/crypto-js.js');
    this.form = fb.group({
      userName: [null, [Validators.required, Validators.minLength(3)]],
      password: [null, Validators.required],
    });
    modalSrv.closeAll();
  }

  submit() {
    this.errorMsg = '';
    Object.values(this.form.controls).forEach(formControl => {
      formControl.markAsDirty();
      formControl.updateValueAndValidity();
      if (formControl.invalid) return;
    });
    const formData = this.form.value;

    // 默认配置中对所有HTTP请求都会强制 [校验](https://ng-alain.com/auth/getting-started) 用户 Token
    // 然一般来说登录请求不需要校验，因此可以在请求URL加上：`/login?_allow_anonymous=true` 表示不触发用户 Token 校验
    this.loading = true;

    this.http
      .get(
        `${baseUrl}/platform/v1/crmsupervisioncenter/login`,
        {
          account: formData.userName,
          password: formData.password,
        },
        {
          headers: {
            token: '',
            autoShowErrorMsg: '', // 不弹出错误提示
          },
        },
      )
      .subscribe((res: any) => {
        if (res.code == 0) {
          // 存储 Token
          // res.token = res.data.token;
          res.token = res.data.token;
          window.localStorage.setItem('UserInfo', JSON.stringify(res.data || {}));

          this.startupSrv.load().then(() => {
            let loginBeforeUrl = window.localStorage.getItem('loginBeforeUrl') || '/';
            if (res.data.screenStyle == 1) {
              loginBeforeUrl = '/data-lsd-jt';
            } else {
              loginBeforeUrl = window.localStorage.getItem('loginBeforeUrl') || '/';
            }

            this.loading = false;
            this.router.navigateByUrl(loginBeforeUrl).then(() => {
              // window.localStorage.removeItem('loginBeforeUrl');
            });
          });
        } else {
          this.loading = false;
          this.errorMsg = res.msg;
          return;
        }
      });
  }
}
