import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
declare var window: Window & { UE: any; _ueId: any };
const UE = window.UE;

@Component({
  selector: 'bd-ueditor',
  templateUrl: './bd-ueditor.component.html',
  styleUrls: ['./bd-ueditor.component.scss'],
})
export class BdUeditorComponent implements OnInit, OnChanges {
  // 是否是内部触发的变更检查
  isInnerChagne = false;

  /**
   * 编辑器配置
   */
  @Input() config = {};
  defaultConfig = {
    topOffset: 68,
    initialFrameWidth: '100%',
    initialFrameHeight: 200,
    // serverUrl : `${baseUrl}/basics/v1/file`,
    // catchRemoteImageEnable: false,// 不抓取远程图片
    toolbars: [
      [
        'source',
        '|',
        'undo', // 撤销
        'redo', // 重做
        '|',
        'fontsize', // 字号
        '|',
        'blockquote', // 引用
        'horizontal', // 分隔线
        '|',
        'removeformat', // 清除格式
        'formatmatch', // 格式刷
        'link', // 超链接
        'unlink', // 取消链接
      ],
      [
        'bold', // 加粗
        'italic', // 斜体
        'underline', // 下划线
        'forecolor', // 字体颜色
        'backcolor', // 背景色
        '|',
        'indent', // 首行缩进
        'justifyleft', // 居左对齐
        'justifyright', // 居右对齐
        'justifycenter', // 居中对齐
        'justifyjustify', // 两端对齐
        '|',
        'rowspacingtop', // 段前距
        'rowspacingbottom', // 段后距
        'lineheight', // 行间距
        '|',
        'insertorderedlist', // 有序列表
        'insertunorderedlist', // 无序列表
        '|',
        'imagenone', // 默认
        'imageleft', // 左浮动
        'imageright', // 右浮动
        'imagecenter', // 居中
        // '|',
        // 'simpleupload',
        // 'insertimage', //多图片上传
        // 'attachment', //附件上传
      ],
    ],
    // xss 过滤是否开启,inserthtml等操作
    xssFilterRules: false,
    // input xss过滤
    inputXssFilter: false,
    // output xss过滤
    outputXssFilter: false,
  };
  get _config() {
    return { ...this.defaultConfig, ...this.config };
  }

  /**
   * 渲染
   */
  @ViewChild('container', null) container: ElementRef;
  ue = null;
  @Output() ready = new EventEmitter();

  /**
   * 双向绑定
   */
  @Input() html = '';
  @Input() isedit = '';
  @Output() htmlChange = new EventEmitter<string>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    const htmlSimpleChanges = changes.html;
    if (!htmlSimpleChanges.firstChange && !this.isInnerChagne) {
      this.ue.setContent(this.html || '');
    }
    this.isInnerChagne = false;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // 设置唯一ID
    window._ueId == null && (window._ueId = 0);
    const id = `ue_${++window._ueId}`;
    this.container.nativeElement.setAttribute('id', id);
    console.log(this.isedit);
    this.ue = UE.getEditor(id, this._config);

    // 渲染完成
    this.ue.addListener('ready', () => {
      this.ue.setContent(this.html || '');

      if (this.isedit === 'true') {
        this.ue.setDisabled();
      }
      this.ready.emit(this.ue);
    });

    // 内容改变
    this.ue.addListener('contentChange', () => {
      this.html = this.ue.getContent();

      this.isInnerChagne = true;
      this.htmlChange.emit(this.html || '');
    });
  }

  ngOnDestroy() {
    this.ue.destroy();
  }
}
