import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@env/environment';

import { TokenValidService } from '@core';
// import { RouterGuardService } from '@core';

// layout
import { LayoutDefaultComponent } from '../layout/default/default.component';
import { LayoutFullScreenComponent } from '../layout/fullscreen/fullscreen.component';
import { LayoutPassportComponent } from '../layout/passport/passport.component';
// dashboard pages
import { DashboardComponent } from './dashboard/dashboard.component';
// passport pages
import { UserLoginComponent } from './passport/login/login.component';
import { UserRegisterComponent } from './passport/register/register.component';
import { UserRegisterResultComponent } from './passport/register-result/register-result.component';
// single pages
import { CallbackComponent } from './callback/callback.component';
import { UserLockComponent } from './passport/lock/lock.component';
import { DataLsdV2Component } from './data-lsd-v2/data-lsd-v2.component';
/**
 * 监管中心页面
 */
import { DataLsdJgComponent } from './data-lsd-jg/data-lsd-jg.component';
// import { DataLsdVideoComponent } from './data-lsd-video/data-lsd-video.component';
// 行政区划企业列表
import { LayoutQuotaComponent } from './quota/layout-quota/layout-quota.component';
import { LayoutQuotaGreatComponent } from './quota/layout-quota-great/layout-quota-great.component';
import { CountsComponent } from './quota/count/counts.component';
// 风险，重大风险数量
import { LayoutRiskComponent } from './quota/layout-risk/layout-risk.component';
import { RiskComponent } from './quota/risk/risk.component';
// 隐患数量
import { DangerComponent } from './quota/danger/danger.component';
// 风险，重大风险数量
import { V2RiskComponent } from './v2quota/v2risk/v2risk.component';
// 风险，重大风险数量
import { V2DangerComponent } from './v2quota/danger/danger.component';
// 监管中心面登录
import { DataLsdJgfreeComponent } from './data-lsd-jgfree/data-lsd-jgfree.component';
// 集团公司安全管理信息中心
import { DataLsdJtComponent } from './data-lsd-jt/data-lsd-jt.component';
import { NoticeComponent } from './notice/notice.component';
// 集团企业列表
import { JtcountsComponent } from './quota/jtcount/jtcounts.component';
// 全屏地图展示
import { AmapFullComponent } from './dashboard/amap-full/amap-full.component';
const routes: Routes = [
  {
    path: '',
    canActivate: [TokenValidService],
    canActivateChild: [TokenValidService],
    children: [
      { path: '', redirectTo: 'data-lsd-jg', pathMatch: 'full' },
      { path: 'data-lsd-jg', component: DataLsdJgComponent, data: { title: '监管中心' } },
      { path: 'data-lsd-jt', component: DataLsdJtComponent, data: { title: '安全管理信息中心' } },
      // { path: 'data-lsd-video', component: DataLsdVideoComponent },
      { path: 'quota/count', component: LayoutQuotaComponent, data: { title: '行政区划企业列表' } },
      { path: 'quota/count-great', component: LayoutQuotaGreatComponent, data: { title: '行政区划企业列表' } },
      { path: 'quota/jtrisk', component: LayoutRiskComponent, data: { title: '风险数量' } },
      { path: 'amapfull', component: AmapFullComponent },


      // { path: 'quota/count', component: CountsComponent, data: { title: '行政区划企业列表' } },
      { path: 'quota/risk', component: RiskComponent, data: { title: '风险数量' } },

      { path: 'quota/danger', component: DangerComponent, data: { title: '隐患数量' } },
      { path: 'v2quota/risk', component: V2RiskComponent, data: { title: '风险数量' } },
      { path: 'v2quota/danger', component: V2DangerComponent, data: { title: '隐患数量' } },
      { path: 'quota/jtcount', component: JtcountsComponent, data: { title: '企业列表' } },
      {
        path: 'data-lsd-v2',
        // canActivate: [RouterGuardService],
        component: DataLsdV2Component,
        data: { title: '安全信息公示平台' }
      }, // 企业大屏第二版
    ],
  },
  // 监管中心免登录
  { path: 'data-lsd-jgfree', component: DataLsdJgfreeComponent, data: { title: '监管中心' } },
  // 全屏布局
  {
    path: 'fullscreen',
    component: LayoutFullScreenComponent,
    children: [],
  },
  // passport
  {
    path: 'passport',
    component: LayoutPassportComponent,
    children: [
      { path: 'login', component: UserLoginComponent, data: { title: '登录' } },
      // { path: 'register', component: UserRegisterComponent, data: { title: '注册' } },
      // { path: 'register-result', component: UserRegisterResultComponent, data: { title: '注册结果' } },
      // { path: 'lock', component: UserLockComponent, data: { title: '锁屏' } },
    ],
  },
  // { path: 'data-lsd-jg', component: DataLsdJgComponent, data: { title: '监管中心' } },//监管大屏
  // 系统公告
  { path: 'notice', component: NoticeComponent },
  // 单页不包裹Layout
  { path: 'callback/:type', component: CallbackComponent },
  { path: '**', redirectTo: 'exception/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class RouteRoutingModule { }
