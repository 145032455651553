import { Component, OnInit } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';
import { baseUrl } from '@core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-counts',
  templateUrl: './jtcounts.component.html',
  styleUrls: ['./jtcounts.component.less'],
})
export class JtcountsComponent implements OnInit {
  baseUrl = baseUrl;
  loading = false;
  searchParams: any = {};
  rows = [];
  pageIndex = 1;
  pageSize = 10;
  total = 0;
  params: any = {};
  level = ['重大风险', '较大风险', '一般风险', '低风险'];

  constructor(private http: _HttpClient, private msg: NzMessageService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  ngOnInit() {
    this.search();
  }

  /**
   * 加载数据
   * @param reset 起始页
   */
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    let url = this.params.type === '1' ? `regulatoryscreens/group/company` : 'regulatoryscreens/group/great/company';
    const searchParams = {
      ...this.params,
      ...this.searchParams,
      page: this.pageIndex,
      limit: this.pageSize,
    };
    delete searchParams.title;
    this.loading = true;

    this.http.get<any>(`${baseUrl}/hazard/v1/${url}`, searchParams).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.rows = res.data.content || [];
        this.total = res.data.totalElements || 0;
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }
}
