// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  SERVER_URL: `./`,
  production: false,
  useHash: true,
  hmr: false,

  domain: './api',
  // domain: 'http://192.168.50.6:9999',
  mockUrl: 'http://106.12.201.179:7300/mock',
  access_token: '',
  YHbaseUrl: 'http://esc.ahjtest.top',
  BZbaseUrl: 'http://aq.ahjtest.top',
  XinbaseUrl: '//jtgh.xinanyun.cn',
  MapUrl: 'https://jtghmin.xinanyun.cn/jtgh',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
