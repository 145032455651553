import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
@Component({
  selector: 'app-layout-quota',
  templateUrl: './layout-quota.component.html',
  styles: []
})
export class LayoutQuotaComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit() {
  }

}
