/**
 * 数据服务
 */

import { Injectable } from '@angular/core';
import { _HttpClient } from '@delon/theme';

import { map } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd';
import { baseUrl } from './constant';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private message: NzMessageService, private http: _HttpClient) {}

  /**
   * 加载 区域/位置 tree 数据
   */
  loadPositionTreeData() {
    return this.http.get<any>(`${baseUrl}/basics/v1/districts/tree`).pipe(
      map(res => {
        //标识叶子节点
        const recursion = arr => {
          arr.forEach(node => {
            node.key = node.id;
            node.title = node.name;

            if (Array.isArray(node.children) && node.children.length) {
              node.expanded = true;
              recursion(node.children);
            } else {
              node.isLeaf = true;
            }
          });
        };
        recursion(res.data || []);
        return res;
      }),
    );
  }
/**
   * 加载 区域/位置 tree 数据 使用code
   */
  loadPositionTreeDataCode() {
    return this.http.get<any>(`${baseUrl}/basics/v1/districts/tree`).pipe(
      map(res => {
        //标识叶子节点
        const recursion = arr => {
          arr.forEach(node => {
            node.key = node.code;
            node.title = node.name;

            if (Array.isArray(node.children) && node.children.length) {
              node.expanded = true;
              recursion(node.children);
            } else {
              node.isLeaf = true;
            }
          });
        };
        recursion(res.data || []);
        return res;
      }),
    );
  }

  /**
   * 加载 管辖部门/单位 tree 数据
   */
  loadDepTreeData() {
    return this.http.get<any>(`${baseUrl}/admin/v1/depts/tree`).pipe(
      map(res => {
        //标识叶子节点
        const recursion = arr => {
          arr.forEach(node => {
            node.key = node.id;
            node.title = node.name;

            if (Array.isArray(node.children) && node.children.length) {
              node.expanded = true;
              recursion(node.children);
            } else {
              node.isLeaf = true;
            }
          });
        };
        recursion(res.data || []);
        return res;
      }),
    );
  }

   /**
   * 加载 管辖部门/单位 tree 数据 使用code
   */
  loadDepTreeDataCode() {
    return this.http.get<any>(`${baseUrl}/admin/v1/depts/tree`).pipe(map(res => {
      //标识叶子节点
      const recursion = (arr) => {
        arr.forEach(node => {
          node.key = node.code;
          node.title = node.name;

          if (Array.isArray(node.children) && node.children.length) {
            node.expanded = true;
            recursion(node.children);
          } else {
            node.isLeaf = true;
          }
        });
      };
      recursion(res.data || []);
      return res;
    }));
  }}
