import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { map } from 'rxjs/operators';
import { baseUrl } from '@core';

import { ActivatedRoute, Params, Router } from '@angular/router';
@Component({
  selector: 'app-v2risk',
  templateUrl: './v2risk.component.html',
  styleUrls: ['./v2risk.component.less'],
})

export class V2RiskComponent implements OnInit {
  window = window;
  title = '当前风险总量';
  /**
   * table
   */
  tableHeight = '';
  loading = false;
  searchParams: any = {
    evaluateType: 1,
    riskLevel: ''
  };
  rows = [];
  pageIndex = 1;
  total = 0;
  pageSize = 5;

  // 地区数据
  areaData = [];
  params: any = {};
  station_nodes: any = [];
  position_nodes = [];
  companyId: number;
  /**
   * 对象类型
   */
  evaluateTypes = [
    { label: '设备设施', value: 1 },
    { label: '作业活动', value: 2 },
    { label: '工艺节点', value: 3 },
    { label: '作业场所', value: 4 },
  ];
  get evaluateTypeMap() {
    let obj = {};
    this.evaluateTypes.forEach(item => obj[item.value] = item.label);
    return obj;
  }

  /**
   * 风险等级
   */
  xycsEmRiskLevels = [
    { label: '重大风险', value: 1 },
    { label: '较大风险', value: 2 },
    { label: '一般风险', value: 3 },
    { label: '低风险', value: 4 },
  ];

  width = '';
  widthList = [];

  widthMap = {
    '1': '30+150+150+150+150+150+150+100+100+150+200+200+200+200+200+100+120+120+160+120+150+100+100',
    '2': '30+150+150+150+150+150+150+150+200+150+200+200+200+200+150+120+120+160+120+120+150+100',
    '3': '30+100+100+150+150+150+150+150+150+150+150+100+100+150+200+200+200+200+200+100+120+160+120+120+150+100',
    '4': '30+150+150+150+150+150+150+150+200+150+200+200+200+200+150+120+120+160+120+120+150+100',
  };
  get xycsEmRiskLevelMap() {
    const obj = {};
    this.xycsEmRiskLevels.forEach(item => obj[item.value] = item.label);
    return obj;
  }

  /**
   * 管控层级
   */
  controllerLevelList = [];
  get controllerLevelMap() {
    const obj = {};
    this.controllerLevelList.forEach(item => obj[item.value] = item.label);
    return obj;
  }
  constructor(
    private fb: FormBuilder,
    private msg: NzMessageService,
    private http: _HttpClient,
    private route: ActivatedRoute,
  ) {
    this.initParam();
    // 加载 管辖部门/单位 tree 数据
    this.loadDepTreeDataCode().subscribe(res => {
      if (res.code === 0) {
        this.station_nodes = res.data || [];
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
    // 加载 区域位置 tree 数据
    this.loadPositionTreeDataCode().subscribe(res => {
      if (res.code === 0) {
        this.position_nodes = res.data || [];
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
    this.tableHeight = `${document.body.getBoundingClientRect().height - 195 - 60 - 60}px`;
  }

  async ngOnInit() {
    await this.getList();// 管控层级
    this.search();
  }

  /**
   * 加载 区域/位置 tree 数据 使用code
   */
  loadPositionTreeDataCode() {
    return this.http.get<any>(`${baseUrl}/basics/v1/districts/tree`, { companyId: this.companyId }).pipe(
      map(res => {
        // 标识叶子节点
        const recursion = arr => {
          arr.forEach(node => {
            node.key = node.code;
            node.title = node.name;

            if (Array.isArray(node.children) && node.children.length) {
              node.expanded = true;
              recursion(node.children);
            } else {
              node.isLeaf = true;
            }
          });
        };
        recursion(res.data || []);
        return res;
      }),
    );
  }
  /**
   * 加载 管辖部门/单位 tree 数据 使用code
   */
  loadDepTreeDataCode() {
    return this.http.get<any>(`${baseUrl}/admin/v1/depts/tree`, { companyId: this.companyId }).pipe(map(res => {
      // 标识叶子节点
      const recursion = (arr) => {
        arr.forEach(node => {
          node.key = node.code;
          node.title = node.name;

          if (Array.isArray(node.children) && node.children.length) {
            node.expanded = true;
            recursion(node.children);
          } else {
            node.isLeaf = true;
          }
        });
      };
      recursion(res.data || []);
      return res;
    }));
  }
  getList() {
    return this.http.get<any>(`${baseUrl}/admin/v1/organization/custom`, { company: this.companyId }).toPromise().then(res => {
      if (res.code == 0) {
        res.data.forEach(item => {
          const data = { label: item.customTypeName, value: item.controlLevel };
          this.controllerLevelList.push(data)
        })

      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }
  getWidth() {
    const widthList = this.widthMap[this.searchParams.evaluateType].split('+');
    return `${widthList.reduce((prev, current) => prev + +current, 0)}px`;
  }

  getWidthList() {
    const widthList = this.widthMap[this.searchParams.evaluateType].split('+');
    return widthList.map(w => `${w}px`);
  }

  /**
   * 加载数据
   * @param reset 起始页
   */
  search(reset: boolean = false) {
    this.width = this.getWidth();
    this.widthList = this.getWidthList();

    if (reset) {
      this.pageIndex = 1;
    }
    const searchParams = {
      ...this.searchParams,
      companyId: this.companyId,
      page: this.pageIndex,
      limit: this.pageSize
    };

    console.log(searchParams);
    Object.entries(searchParams).forEach(([key, value]) => { value === null && delete searchParams[key]; });
    this.loading = true;
    this.http.get<any>(`${baseUrl}/hazard/v1/risk/equipments/regionalRiskPointMoreList`, searchParams).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.rows = res.data.content || [];
        this.total = res.data.totalElements || 0;
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }
  private initParam() {
    this.route.queryParams.subscribe((params: Params) => {
      this.params = params;
      this.companyId = params.companyId;
      if (params.type) {
        this.searchParams.checkResult = 0;
        if (params.type == 1) {
          this.title = '当前风险总量';
          this.searchParams.riskLevel = this.searchParams.riskLevel;
        } else if (params.type == 2) {
          this.title = '重大风险列表';
          this.searchParams.riskLevel = 1
        } else if (params.type == 3) {
          this.title = '较大风险列表';
          this.searchParams.riskLevel = 2
        } else if (params.type == 4) {
          this.title = '一般风险列表';
          this.searchParams.riskLevel = 3
        } else if (params.type == 5) {
          this.title = '低风险列表';
          this.searchParams.riskLevel = 4
        }
      }
    });
  }
}
