import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';
import { HttpParams } from '@angular/common/http';
import { baseUrl, CommonService } from '@core';
import { ActivatedRoute } from '@angular/router';
import { JtRiskComponent } from '../jtrisk/jtrisk.component';
@Component({
  selector: 'app-jtcompanies-list',
  templateUrl: './jtcompanies-list.component.html',
  styles: []
})
export class JtCompaniesListComponent implements OnInit {
  baseUrl = baseUrl;
  loading = false;
  searchParams: any = {};
  rows = [];
  pageIndex = 1;
  pageSize = 10;
  total = 0;
  params: any = {};


  riskLevel: any = [{
    name: '重大风险',
    value: 1
  }, {
    name: '较大风险',
    value: 2
  }, {
    name: '一般风险',
    value: 3
  }, {
    name: '低风险',
    value: 4
  }]

  constructor(
    private http: _HttpClient,
    private msg: NzMessageService,
    private modal: ModalHelper,
    private route: ActivatedRoute,
    public commonService: CommonService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  ngOnInit() {
    this.search();
  }

  /**
   * 加载数据
   * @param reset 起始页
   */
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    if (this.searchParams.industry == null) {
      this.searchParams.industry = '';
    }
    const searchParams = {
      ...this.params,
      ...this.searchParams,
      page: this.pageIndex,
      limit: this.pageSize,
    };

    this.loading = true;
    this.http.get<any>(`${baseUrl}/hazard/v1/regulatoryscreens/company/risk/list`, searchParams).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.rows = res.data.content || [];
        this.total = res.data.totalElements || 0;

      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }
  /**
   * 打开风险数量弹框
   */
  openRisk(companyId){
    this.modal.create(JtRiskComponent,{ companyId,Jttype:this.params.type }, {
      size:1200,
      modalOptions: {
        nzMaskClosable: false,
      },
    },
    ).subscribe(() => {
      this.search()
    });
  }
}
