import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '@delon/theme';
import { _HttpClient } from '@delon/theme';
import { baseUrl, domain } from '@core';
import { CommonService } from '@core';
import { NzMessageService } from 'ng-zorro-antd';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';

@Component({
  selector: 'header-user',
  template: `
    <div
      class="alain-default__nav-item d-flex align-items-center px-sm"
      nz-dropdown
      nzPlacement="bottomRight"
      [nzDropdownMenu]="userMenu"
    >
      <nz-avatar [nzSrc]="settings.user.avatar" nzSize="small" class="mr-sm"></nz-avatar>
      {{ settings.user.name }}
    </div>
    <nz-dropdown-menu #userMenu="nzDropdownMenu">
      <div nz-menu class="width-sm">
        <div nz-menu-item routerLink="/pro/account/center">
          <i nz-icon nzType="user" class="mr-sm"></i>
          {{ 'menu.account.center' | translate }}
        </div>
        <div nz-menu-item routerLink="/pro/account/settings">
          <i nz-icon nzType="setting" class="mr-sm"></i>
          {{ 'menu.account.settings' | translate }}
        </div>
        <div nz-menu-item routerLink="/exception/trigger">
          <i nz-icon nzType="close-circle" class="mr-sm"></i>
          {{ 'menu.account.trigger' | translate }}
        </div>
        <li nz-menu-divider></li>
        <div nz-menu-item (click)="logout()">
          <i nz-icon nzType="logout" class="mr-sm"></i>
          {{ 'menu.account.logout' | translate }}
        </div>
      </div>
    </nz-dropdown-menu>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderUserComponent {
  constructor(
    public settings: SettingsService,
    private router: Router,
    private message: NzMessageService,
    private http: _HttpClient,
    private commonService: CommonService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) { }

  logout() {
    // this.tokenService.clear();
    // this.router.navigateByUrl(this.tokenService.login_url!);
    const authToken = JSON.parse(window.localStorage.getItem('UserInfo') || '{}').token;
    this.http.get(`${baseUrl}/platform/v1/crmsupervisioncenter/out?token=${authToken}`, {}, {
      headers: {
        token: ''
      }
    }).subscribe(res => {
      if (res.code == 0) {
        this.tokenService.clear();
        // window.localStorage.removeItem('UserInfo');
        // let UserInfo = JSON.parse(window.localStorage.getItem('UserInfo') || '{}');

        this.commonService.loginBeforeUrl = window.location.hash.slice(1);
        window.location.reload();
        this.router.navigateByUrl(this.tokenService.login_url!);
        this.message.success("退出成功！");
      }
    });
  }
}

