import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { CommonService, baseUrl } from '@core';
import { EditComponent } from './edit/edit.component';
import { DetailComponent } from './detail/detail.component';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.less'],
})
export class NoticeComponent implements OnInit {
  loading = false;

  rows = [];
  pageIndex = 1;
  pageSize = 20;
  total = 0;
  limit = 20;
  order = '';
  page = 1;
  sort = '';
  searchParams: any = {};
  areaData: any = {};
  basePar: any = {};
  path = '/data-lsd-jg';
  // 当前选中的节点
  selectNode: any = {};
  companyName = JSON.parse(window.localStorage.getItem('UserInfo') || '{}').supervisionOrganizationName;

  // 全选
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  mapOfCheckedId: { [key: string]: boolean } = {};
  numberOfChecked = 0;

  checkedIds: any;

  refreshStatus(e: any): void {
    this.isAllDisplayDataChecked = this.rows.filter(item => !item.disabled).every(item => this.mapOfCheckedId[item.id]);

    this.isIndeterminate =
      this.rows.filter(item => !item.disabled).some(item => this.mapOfCheckedId[item.id]) &&
      !this.isAllDisplayDataChecked;

    this.numberOfChecked = this.rows.filter(item => this.mapOfCheckedId[item.id]).length;
    this.checkedIds = Object.entries(this.mapOfCheckedId)
      .filter(([id, checked]) => checked)
      .map(([id]) => id);
  }

  checkAll(value: boolean): void {
    this.rows.filter(item => !item.disabled).forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus('');
  }

  constructor(
    private http: _HttpClient,
    private message: NzMessageService,
    private modal: ModalHelper,
    public commonService: CommonService,
    public router: Router,
    private routeInfo: ActivatedRoute,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {}

  ngOnInit() {
    this.path = this.routeInfo.snapshot.queryParams.path;
    this.search();

    this.http.get<any>(`${baseUrl}/hazard/v1/regulatoryscreens/notice/comboBox/area`).subscribe(res => {
      if (res.code === 0) {
        this.areaData = res.data || {};
        this.basePar = {
          code: res.data.code,
          areaLevel: res.data.level,
          levelName: res.data.levelName,
        };
      }
    });
  }

  /**
   * 加载数据
   * @ param reset
   */
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    const searchParams = {
      ...this.searchParams,
      limit: this.pageSize,
      page: this.pageIndex,
    };

    this.loading = true;
    this.http.get<any>(`${baseUrl}/hazard/v1/regulatoryscreens/notice/list`, searchParams).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.rows = res.data.content || [];
        this.total = res.data.totalElements || 0;
      }
    });
  }

  /**
   * 打开 添加 对话框
   */
  onOpenEditDialog() {
    this.modal
      .create(
        EditComponent,
        { basePar: this.basePar },
        {
          size: 1200,
          modalOptions: {
            nzMaskClosable: false,
          },
        },
      )
      .subscribe(res => {
        if (res) {
          this.search();
        }
      });
  }

  /**
   * 打开 详情 对话框
   */
  onOpenDetailDialog(data = null) {
    this.modal
      .create(
        DetailComponent,
        { id: data.id, basePar: this.basePar },
        {
          size: 1200,
          modalOptions: {
            nzMaskClosable: false,
          },
        },
      )
      .subscribe(res => {
        if (res) {
          this.search();
        }
      });
  }

  /**
   * 删除
   */
  onDelete(data=null) {
    const id = [];
    if (data) {
      id.push(data.id);
    } else {
      for (const key in this.mapOfCheckedId) {
        if (this.mapOfCheckedId[key]) {
          id.push(key);
        }
      }
    }

    this.http.delete(`${baseUrl}/hazard/v1/regulatoryscreens/notice/delete?id=` + id).subscribe(res => {
      if (res.code === 0) {
        this.message.success(res.msg);
        this.search();
      } else {
        this.message.error(res.msg || '操作失败');
      }
    });
  }

  /**
   * 退出登录
   */
  logout() {
    const authToken = JSON.parse(window.localStorage.getItem('UserInfo') || '{}').token;
    this.http.get(`${baseUrl}/platform/v1/crmsupervisioncenter/out?token=${authToken}`).subscribe(res => {
      if (res.code === 0) {
        this.tokenService.clear();
        window.localStorage.removeItem('UserInfo');
        this.commonService.loginBeforeUrl = window.location.hash.slice(1);
        this.router.navigate(['/passport/login']);

        this.message.success('退出成功！');
      }
    });
  }
}
