import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
  HttpResponseBase,
  HttpEventType,
  HttpResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) { }

  private get msg(): NzMessageService {
    return this.injector.get(NzMessageService);
  }

  private goTo(url: string) {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /**
     * 拦截请求
     */
    let headers = req.headers;

    // Token(登录状态标识)
    // 如果预设了 token = '', 则代表该请求不需要token
    if (headers.has('token')) {
      if (headers.get('token') === '') {
        headers = headers.delete('token');
      }
    } else {
      const authToken = JSON.parse(window.localStorage.getItem('UserInfo') || '{}').token;
      if (authToken) {
        // headers = headers.append('Authorization', 'Bearer ' + authToken);
        headers = headers.append('Supervision-Center-Token', authToken)
      }
    }

    // 是否自动弹出错误消息
    // 如果在请求头中预设了 autoShowErrorMsg = '', 则代表不自动弹出错误消息
    let autoShowErrorMsg = true;
    headers.get('autoShowErrorMsg') === '' && (autoShowErrorMsg = false);
    headers = headers.delete('autoShowErrorMsg');

    req = req.clone({
      headers
    });

    /**
     * 拦截响应
     */
    return next.handle(req).pipe(
      map((event) => {
        // 判断是否接收到了响应体
        if (event.type == HttpEventType.Response) {
          const body = event.body || {};
          switch (true) {
            // //未登录、token无效、token过期 => 跳转到登录页
            // case body.code == -1: {
            //   window['localStorage'].removeItem('UserInfo');
            //   this.goTo('/passport/login');
            //   break;
            // }

            // 业务处理错误 => 弹出错误消息
            case (body.code !== undefined) && (body.code != 0): {
              autoShowErrorMsg && this.msg.error(body.msg || body.message || `${event.url} 业务处理异常!`);
              break;
            }
          }
        }
        return event;
      }),
      // HTTP 异常处理
      catchError((event) => {
        // 根据HTTP状态码进行不同的操作
        switch (event.status) {
          // 未登录、token无效、token过期 => 跳转到登录页
          case 401: {

            // 清空 token 信息
            window.localStorage.removeItem('UserInfo');
            this.goTo('/passport/login');
            autoShowErrorMsg = false;
            break;
          }
          case 500: { break; }
          case 500: { break; }
          default: { break; }
        }

        const msg = (event.error || {}).msg || (event.error || {}).message || event.message || 'HTTP请求发生异常';
        autoShowErrorMsg && this.msg.error(msg);

        // 对于json请求, 则回放一个正常的响应体
        if (req.responseType == 'json' || !req.responseType) {
          const httpResponse = new HttpResponse({
            body: {
              code: 100,  // code = 100, 代表这是一个HTTP异常请求, 经过异常处理后的响应体
              msg,
              response: event
            }
          });
          return of(httpResponse);
        }

        throw event;
      })
    )
  }
}
