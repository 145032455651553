import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';
import { HttpParams } from '@angular/common/http';
import { baseUrl, CommonService } from '@core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-quota-counts',
  templateUrl: './counts.component.html',
  styleUrls: ['./counts.component.less'],
})
export class CountsComponent implements OnInit {
  baseUrl = baseUrl;
  loading = false;
  searchParams: any = {};
  rows = [];
  pageIndex = 1;
  pageSize = 10;
  total = 0;
  params: any = {};

  constructor(
    private http: _HttpClient,
    private msg: NzMessageService,
    private modal: ModalHelper,
    private route: ActivatedRoute,
    public commonService: CommonService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  ngOnInit() {
    this.search();
  }

  /**
   * 加载数据
   * @param reset 起始页
   */
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    if (this.searchParams.industry == null) {
      this.searchParams.industry = '';
    }
    const searchParams = {
      ...this.params,
      ...this.searchParams,
      page: this.pageIndex,
      limit: this.pageSize,
    };

    this.loading = true;
    this.http.get<any>(`${baseUrl}/hazard/v1/regulatoryscreens/area-risk-rank`, searchParams).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.rows = res.data || [];
        this.total = res.data.totalElements || 0;
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }
}
