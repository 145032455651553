import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { DomSanitizer } from '@angular/platform-browser';

import { _HttpClient } from '@delon/theme';
import { baseUrl, domain, CommonService, MapUrl } from '@core';

import { ActivatedRoute, Router, ActivationEnd, RouteReuseStrategy, ActivatedRouteSnapshot } from '@angular/router';

import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';

import * as G2 from '@antv/g2';

import { format } from 'date-fns';

// const $ = window.jQuery;
// const jQuery = window.jQuery;
declare var window: Window & { jQuery: any; echarts: any };

const $ = window.jQuery;
const jQuery = window.jQuery;
import '../../../assets/lib/jquery.SuperSlide.2.1.3.js';

const hazardUrl = `${domain}/hazard/v1`;
const basicsUrl = `${domain}/basics/v1`;

import { henanCitys } from './citys';
const echarts = window.echarts;

export interface RateInterface {
  code: string | number;
  msg: string;
  data: RateDataInterface[];
}
export interface RateDataInterface {
  name: string;
  yearZhengGaiLv: string;
}
@Component({
  selector: 'app-data-lsd-jt',
  templateUrl: './data-lsd-jt.component.html',
  styleUrls: ['./data-lsd-jt.component.scss'],
})
export class DataLsdJtComponent implements OnInit {
  // 查询参数
  params: any = {
    // code: '410000',
    // areaLevel: 'province',
  };
  searchParams: any = {};
  loading = false;
  vloading = false;
  Aloading = false;
  totalDataLoading = false;
  isReload = false;

  tj1_carousel_autoPlay = true;
  tj2_carousel_autoPlay = true;

  year = Number(format(new Date(), 'YYYY'));

  up = false;
  down = true;

  // 统计数量
  totalData: any = {
    yearZhengGaiLvLowestSAll: [],
  };

  lowData: any = {
    yearZhengGaiLvLowestSAll: [],
  };

  /**
   * echartsOption
   */
  // 各企业本年隐患数量
  dqbnyhEchartsOption = null;
  qyyhlbEchartsOption = null;
  // 安全一张图
  currentAreaName = '';
  // 监管中心名称
  supervisionCenterName = '';
  matchingType = 0;
  mapEchartsOption = null;

  // 不同等级风险数量统计
  btfxdjEchartsOption = null;
  // 年度隐患数量及整改情况统计
  jdyhzgqkEchartsOption = null;

  // 各企业风险数量统计
  fxEchartsOption = null;
  // 各地区未闭环的隐患数量统计
  wbhyhEchartsOption = null;
  // 各地区本月新增隐患数量统计
  xzyhEchartsOption = null;
  // 近一年隐患变化趋势
  ynqsEchartsOption = null;

  // 地区风险排行及不同风险水平企业数量
  qyfxNumberTotal = null;
  qyfxNumberRows = [];

  IntervalId = null;

  authToken = '';

  isVisible = false;
  modalTitle = '年度隐患整改率';
  rateIsVisible = false;
  rateData;
  rateNum: any = [];
  constructor(
    public sanitizer: DomSanitizer,
    private message: NzMessageService,
    private http: _HttpClient,
    private eleMentRef: ElementRef,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
    /**
     * jQuery.fn.slide 修正
     */
    jQuery.easing.jswing = jQuery.easing.swing;
    jQuery.extend(jQuery.easing, {
      def: 'easeOutQuad',
      swing(a, b, c, d, e) {
        return jQuery.easing[jQuery.easing.def](a, b, c, d, e);
      },
      easeInQuad(a, b, c, d, e) {
        return d * (b /= e) * b + c;
      },
      easeOutQuad(a, b, c, d, e) {
        return -d * (b /= e) * (b - 2) + c;
      },
      easeInOutQuad(a, b, c, d, e) {
        return (b /= e / 2) < 1 ? (d / 2) * b * b + c : (-d / 2) * (--b * (b - 2) - 1) + c;
      },
      easeInCubic(a, b, c, d, e) {
        return d * (b /= e) * b * b + c;
      },
      easeOutCubic(a, b, c, d, e) {
        return d * ((b = b / e - 1) * b * b + 1) + c;
      },
      easeInOutCubic(a, b, c, d, e) {
        return (b /= e / 2) < 1 ? (d / 2) * b * b * b + c : (d / 2) * ((b -= 2) * b * b + 2) + c;
      },
      easeInQuart(a, b, c, d, e) {
        return d * (b /= e) * b * b * b + c;
      },
      easeOutQuart(a, b, c, d, e) {
        return -d * ((b = b / e - 1) * b * b * b - 1) + c;
      },
      easeInOutQuart(a, b, c, d, e) {
        return (b /= e / 2) < 1 ? (d / 2) * b * b * b * b + c : (-d / 2) * ((b -= 2) * b * b * b - 2) + c;
      },
      easeInQuint(a, b, c, d, e) {
        return d * (b /= e) * b * b * b * b + c;
      },
      easeOutQuint(a, b, c, d, e) {
        return d * ((b = b / e - 1) * b * b * b * b + 1) + c;
      },
      easeInOutQuint(a, b, c, d, e) {
        return (b /= e / 2) < 1 ? (d / 2) * b * b * b * b * b + c : (d / 2) * ((b -= 2) * b * b * b * b + 2) + c;
      },
      easeInSine(a, b, c, d, e) {
        return -d * Math.cos((b / e) * (Math.PI / 2)) + d + c;
      },
      easeOutSine(a, b, c, d, e) {
        return d * Math.sin((b / e) * (Math.PI / 2)) + c;
      },
      easeInOutSine(a, b, c, d, e) {
        return (-d / 2) * (Math.cos((Math.PI * b) / e) - 1) + c;
      },
      easeInExpo(a, b, c, d, e) {
        return 0 == b ? c : d * Math.pow(2, 10 * (b / e - 1)) + c;
      },
      easeOutExpo(a, b, c, d, e) {
        return b == e ? c + d : d * (-Math.pow(2, (-10 * b) / e) + 1) + c;
      },
      easeInOutExpo(a, b, c, d, e) {
        return 0 == b
          ? c
          : b == e
            ? c + d
            : (b /= e / 2) < 1
              ? (d / 2) * Math.pow(2, 10 * (b - 1)) + c
              : (d / 2) * (-Math.pow(2, -10 * --b) + 2) + c;
      },
      easeInCirc(a, b, c, d, e) {
        return -d * (Math.sqrt(1 - (b /= e) * b) - 1) + c;
      },
      easeOutCirc(a, b, c, d, e) {
        return d * Math.sqrt(1 - (b = b / e - 1) * b) + c;
      },
      easeInOutCirc(a, b, c, d, e) {
        return (b /= e / 2) < 1
          ? (-d / 2) * (Math.sqrt(1 - b * b) - 1) + c
          : (d / 2) * (Math.sqrt(1 - (b -= 2) * b) + 1) + c;
      },
      easeInElastic(a, b, c, d, e) {
        let f = 1.70158,
          g = 0,
          h = d;
        return 0 == b
          ? c
          : 1 == (b /= e)
            ? c + d
            : (g || (g = 0.3 * e),
              h < Math.abs(d) ? ((h = d), (f = g / 4)) : (f = (g / (2 * Math.PI)) * Math.asin(d / h)),
              -(h * Math.pow(2, 10 * (b -= 1)) * Math.sin(((b * e - f) * 2 * Math.PI) / g)) + c);
      },
      easeOutElastic(a, b, c, d, e) {
        let f = 1.70158,
          g = 0,
          h = d;
        return 0 == b
          ? c
          : 1 == (b /= e)
            ? c + d
            : (g || (g = 0.3 * e),
              h < Math.abs(d) ? ((h = d), (f = g / 4)) : (f = (g / (2 * Math.PI)) * Math.asin(d / h)),
              h * Math.pow(2, -10 * b) * Math.sin(((b * e - f) * 2 * Math.PI) / g) + d + c);
      },
      easeInOutElastic(a, b, c, d, e) {
        let f = 1.70158,
          g = 0,
          h = d;
        return 0 == b
          ? c
          : 2 == (b /= e / 2)
            ? c + d
            : (g || (g = e * 0.3 * 1.5),
              h < Math.abs(d) ? ((h = d), (f = g / 4)) : (f = (g / (2 * Math.PI)) * Math.asin(d / h)),
              1 > b
                ? -0.5 * h * Math.pow(2, 10 * (b -= 1)) * Math.sin(((b * e - f) * 2 * Math.PI) / g) + c
                : 0.5 * h * Math.pow(2, -10 * (b -= 1)) * Math.sin(((b * e - f) * 2 * Math.PI) / g) + d + c);
      },
      easeInBack(a, b, c, d, e, f) {
        return void 0 == f && (f = 1.70158), d * (b /= e) * b * ((f + 1) * b - f) + c;
      },
      easeOutBack(a, b, c, d, e, f) {
        return void 0 == f && (f = 1.70158), d * ((b = b / e - 1) * b * ((f + 1) * b + f) + 1) + c;
      },
      easeInOutBack(a, b, c, d, e, f) {
        return (
          void 0 == f && (f = 1.70158),
          (b /= e / 2) < 1
            ? (d / 2) * b * b * (((f *= 1.525) + 1) * b - f) + c
            : (d / 2) * ((b -= 2) * b * (((f *= 1.525) + 1) * b + f) + 2) + c
        );
      },
      easeInBounce(a, b, c, d, e) {
        return d - jQuery.easing.easeOutBounce(a, e - b, 0, d, e) + c;
      },
      easeOutBounce(a, b, c, d, e) {
        return (b /= e) < 1 / 2.75
          ? d * 7.5625 * b * b + c
          : 2 / 2.75 > b
            ? d * (7.5625 * (b -= 1.5 / 2.75) * b + 0.75) + c
            : 2.5 / 2.75 > b
              ? d * (7.5625 * (b -= 2.25 / 2.75) * b + 0.9375) + c
              : d * (7.5625 * (b -= 2.625 / 2.75) * b + 0.984375) + c;
      },
      easeInOutBounce(a, b, c, d, e) {
        return e / 2 > b
          ? 0.5 * jQuery.easing.easeInBounce(a, 2 * b, 0, d, e) + c
          : 0.5 * jQuery.easing.easeOutBounce(a, 2 * b - e, 0, d, e) + 0.5 * d + c;
      },
    });

    this.route.queryParams.subscribe(params => {
      const { code, areaLevel, isJump, account } = params;
      if (isJump) {
        this.params.isJump = Number(isJump);
      }
      if (account) {
        this.params.account = account;
      }
      if (code) {
        this.params.code = code;
        // this.params.code = this.params.code + '0'.repeat(6 - this.params.code.length);
      }
      if (areaLevel) {
        this.params.areaLevel = areaLevel;
      } else if (this.params.code) {
        // if (code == '410000') {
        //   this.params.areaLevel = 'province';
        // } else if (henanCitys.find(city => city.adcode == code)) {
        //   this.params.areaLevel = 'city';
        // }

        // 通过 code 来识别 areaLevel
        // 国 100000
        // 省 410000
        // 市 411600
        // 县 411622 district
        if (this.params.code.slice(2) == '0000') {
          this.params.areaLevel = 'province';
        } else if (this.params.code.slice(4) == '00') {
          this.params.areaLevel = 'city';
        } else {
          this.params.areaLevel = 'district';
        }
      }
      if (this.params.code) {
        this.isReload = false;
        // let pcode = this.params.code.slice(0, 6);
        // if (this.params.code.includes('KGZ')) {
        //   pcode = this.params.code.slice(0, 9);
        //   this.params.code = this.params.code.slice(3, 9);
        // }
        let pcode = this.params.code;
        if (this.params.code.includes('KGZ')) {
          pcode = this.params.code;
          this.params.code = this.params.code.slice(3);
        }
        /**
         * 加载地图数据
         */
        const jsonUrl = `${MapUrl}/geojson/` + pcode + '.json';
        // //判断是否是地级市
        // if (henanCitys.find(city => city.adcode == this.params.code)) {
        //   jsonUrl = `./assets/henan-map-json/${this.params.code}_full.json`;
        // } else if(this.params.code == '420107') {
        //   //武汉 青山区
        //   jsonUrl = `./assets/henan-map-json/420107.json`;
        // } else {
        //   //默认显示全省
        //   jsonUrl = `./assets/henan-map-json/410000_full.json`;
        // }

        // if(this.params.areaLevel == 'district') {
        //   jsonUrl = `https://geo.datav.aliyun.com/areas/bound/${this.params.code}.json`
        // } else {
        //   jsonUrl = `https://geo.datav.aliyun.com/areas/bound/${this.params.code}_full.json`
        // }

        this.http
          .get(
            jsonUrl,
            {},
            {
              headers: {
                autoShowErrorMsg: '', // 不弹出错误提示
              },
            },
          )
          .subscribe((res: any) => {
            if (res.code == 100) {
              this.http.get(`${MapUrl}/geojson/KGZ100000.json`).subscribe(item => {
                echarts.registerMap('henan', item);
                this.load();
              });
              return;
            }
            echarts.registerMap('henan', res);
            this.load();
          });
      } else {
        const href = window.location.href;
        // if (href.includes('?')) {
        //   window.location.href = `${href.slice(0, href.indexOf('?'))}?code=${code || 410000}&areaLevel=${areaLevel || 'province'}`
        // } else {
        //   window.location.href = `${href}?code=${code || 410000}&areaLevel=${areaLevel || 'province'}`
        // }
        // 获取登录返回的自定义code
        let customCode = JSON.parse(window.localStorage.getItem('UserInfo') || '{}').code;
        if (customCode.includes('KGZ')) {
          customCode = customCode.slice(0, 9);
          this.params.code = customCode.slice(3, 9);
        }
        const customAreaLevel = JSON.parse(window.localStorage.getItem('UserInfo') || '{}').areaLevel;
        // if (href.includes('?')) {
        //   window.location.href = `${href.slice(0, href.indexOf('?'))}?code=${code || ''}&areaLevel=${areaLevel || ''}`
        // } else {
        //   window.location.href = `${href}?code=${code || ''}&areaLevel=${areaLevel || ''}`
        // }
        if (href.includes('?')) {
          window.location.href = `${href.slice(
            0,
            href.indexOf('?'),
          )}?code=${customCode}&areaLevel=${customAreaLevel}&isJump=0`;
        } else {
          window.location.href = `${href}?code=${customCode}&areaLevel=${customAreaLevel}&isJump=0`;
        }
      }
    });

    /**
     * 5分钟轮训一次
     */
    this.IntervalId = setInterval(() => {
      this.isReload = true;
      this.params.code && this.params.areaLevel && this.load();
    }, 5 * 60 * 1000);
  }

  ngOnInit() {
    // 获取登录的监管中心名称
    this.supervisionCenterName = JSON.parse(window.localStorage.getItem('UserInfo') || '{}').supervisionCenterName;
    // 获取登录的监管中心匹配方式
    this.matchingType = JSON.parse(window.localStorage.getItem('UserInfo') || '{}').matchingType;
    // 添加 轮播图 播放暂停 事件
    setTimeout(() => {
      Array.from(this.eleMentRef.nativeElement.querySelectorAll('[autoPlay_field]')).forEach(
        (carousel: HTMLElement) => {
          const autoPlay_field = carousel.getAttribute('autoPlay_field');
          carousel.addEventListener('mouseenter', e => {
            this[autoPlay_field] = false;
          });
          carousel.addEventListener('mouseleave', e => {
            this[autoPlay_field] = true;
          });
        },
      );
    }, 0);
  }
  change(type: boolean) {
    const to = Number(format(new Date(), 'YYYY'));
    if (type) {
      this.year = this.year + 1;
      this.down = true;
      if (this.year === to) {
        this.up = false;
      } else {
        this.up = true;
      }
    } else {
      this.year = this.year - 1;
      this.up = true;
      if (this.year === 2019) {
        this.down = false;
      } else {
        this.down = true;
      }
    }
    this.load();
  }

  ngOnDestroy() {
    clearInterval(this.IntervalId);
  }
  queryValueUp(code = null, arealevel = null) {
    const params = this.getParams();
    return {
      code: code ? code : params.code,
      areaLevel: arealevel ? arealevel : params.areaLevel,
      isJump: Number(params.isJump) - 1,
    };
  }
  queryValueDown(item) {
    const params = this.getParams();
    return { code: item.code, areaLevel: item.areaLevel, isJump: Number(params.isJump) + 1 };
  }

  load() {
    /**
     * 地区风险排行及不同风险水平企业数量、安全一张图、不同等级风险数量统计、各企业风险数量统计、安全监管指标(风险数据部分)
     */
    if (!this.isReload) {
      this.loading = true;
      this.btfxdjEchartsOption = null;
      this.fxEchartsOption = null;
      this.mapEchartsOption = null;
    }

    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/riskRanking`, params).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.currentAreaName = res.data.currentAreaName || '河南省';
        this.loadTjsl(res.data.regulatoryIndicators || {}); // 安全监管指标
        this.loadLow(); // 最低整改率
        this.loadqysl(); // 本年隐患按类别统计
        this.loadBtfxdj(res.data.differentLevelsRiskNumber || {}); // 不同等级风险数量统计
        this.loadMapData(res.data); // 安全一张图

        this.loaddqbnyh(); // 各企业本年隐患情况统计
        this.loadjdyhzgqk(); // 年度隐患数量及整改情况统计
        this.loadFx(); // 各企业风险数量统计
        this.loadWbhyh(); // 各企业未闭环隐患情况统计
        this.loadxzyh(); // 各企业本月新增隐患情况统计
        this.loadynqs(); // 近一年隐患数量变化趋势
      } else if (res.code == 100) {
        this.router.navigateByUrl('/passport/login');
        return;
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 获取请求参数
   */
  getParams() {
    if (this.params.code.includes('KGZ')) {
      this.params.code = this.params.code.slice(3, 9);
    }
    return {
      // access_token: JSON.parse(window.localStorage.getItem('_token') || '{}').token,
      year: this.year,
      ...this.params,
    };
  }

  /**
   * 本年隐患按类别统计
   */
  loadqysl() {
    if (!this.isReload) {
      this.qyyhlbEchartsOption = null;
    }
    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/groupCompany/potentialType`, params).subscribe(res => {
      if (res.code === 0) {
        const arr = res.data || [];

        this.qyyhlbEchartsOption = {
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ',
          },

          series: [
            {
              name: '隐患类别',
              type: 'pie',
              radius: [30, 110],
              center: ['50%', '50%'],
              roseType: 'radius',
              label: {
                formatter: '{b}\n{c}',
              },
              // emphasis: {
              //   label: {
              //     show: true,
              //   },
              // },
              data: arr.map(item => ({
                value: item.total,
                name: item.potentialCategoryName,
              })),
            },
          ],
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 各企业本年隐患数量
   */
  loaddqbnyh() {
    if (!this.isReload) {
      this.dqbnyhEchartsOption = null;
    }
    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/groupCompany/year`, params).subscribe(res => {
      if (res.code === 0) {
        const riskTypes = [
          { name: '重大隐患', field: 'majorCount' },
          { name: '一般隐患', field: 'generalCount' },
        ];

        const arr = res.data || [];
        arr.forEach(item => {
          if (item.name.length > 6) {
            item.name = item.name.slice(0, 6) + '...';
          }
        });
        this.dqbnyhEchartsOption = {
          grid: {
            left: 0,
            right: 40,
            containLabel: true,
            bottom: 8,
            top: 30,
          },
          legend: {
            left: 0,
            textStyle: {
              color: '#c7fffe',
            },
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          xAxis: {
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
            },
            splitLine: {
              lineStyle: {
                color: '#044d67',
              },
            },
          },
          yAxis: {
            type: 'category',
            data: arr.map(v => v.name),
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2,
            },
          },
          dataZoom: [
            {
              type: 'slider',
              yAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0,
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1,
                },
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf',
              },
              height: '100%',
              width: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent',
              },
              bottom: 0,
            },
            {
              type: 'inside',
              yAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.hiddencolor),
          series: riskTypes.map((type, i) => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barMaxWidth: '5',
            stack: '总量',
            label: {
              normal: {
                show: i == 1,
                position: 'right',
                color: '#c7fffe',
                formatter: params => {
                  return (
                    this.dqbnyhEchartsOption &&
                    this.dqbnyhEchartsOption.series.reduce((total, serie) => total + serie.data[params.dataIndex], 0)
                  );
                },
              },
            },
          })),
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 加载统计数量
   */
  loadTjsl(regulatoryIndicators) {
    if (!this.isReload) {
      this.totalDataLoading = true;
    }
    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/getRegulatoryIndicators`, params).subscribe(res => {
      this.totalDataLoading = false;
      if (res.code == 0) {
        this.totalData = res.data || {};
        this.totalData = { ...this.totalData, ...regulatoryIndicators };
        this.totalData.yearZhengGaiLvLowest1 = (res.data.yearZhengGaiLvLowest || [])[0];
        this.totalData.yearZhengGaiLvLowestSAll = (res.data.yearZhengGaiLvLowest || []).slice(1);
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 加载最低整改率
   */
  loadLow() {
    if (!this.isReload) {
      this.totalDataLoading = true;
    }
    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/group/company/year-rectification`, params).subscribe(res => {
      this.totalDataLoading = false;
      if (res.code == 0) {
        console.log(res, 222);
        this.lowData = res.data || {};
        // this.totalData = { ...this.totalData, ...regulatoryIndicators };
        this.lowData.yearZhengGaiLvLowest1 = (res.data.yearZhengGaiLvLowest || [])[0];
        this.lowData.yearZhengGaiLvLowestSAll = (res.data.yearZhengGaiLvLowest || []).slice(1);
        this.rateData = res.data.correctionRateRanking || [];
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 加载地图数据
   */
  loadMapData(data) {
    this.mapEchartsOption = {
      tooltip: {
        confine: true,
      },
      visualMap: [
        {
          type: 'piecewise',
          seriesIndex: 0,
          bottom: '15%',
          pieces: [
            { gte: 62, color: '#c20037', label: '重大风险区域' },
            { gte: 34, lt: 62, color: '#ff8a00', label: '较大风险区域' },
            { gte: 15, lt: 34, color: '#fff000', label: '一般风险区域' },
            { lt: 15, color: '#0095ff', label: '低风险区域' },
          ],
          inverse: true,
          textStyle: {
            color: '#d4fff9',
          },
        },
        {
          type: 'piecewise',
          seriesIndex: 1,
          left: 'auto',
          right: 0,
          bottom: '15%',
          pieces: [
            { gte: 62, color: '#c20037', label: '重大风险企业' },
            { gte: 34, lt: 62, color: '#ff8a00', label: '较大风险企业' },
            { gte: 15, lt: 34, color: '#fff000', label: '一般风险企业' },
            { lt: 15, color: '#0095ff', label: '低风险企业' },
          ],
          inverse: true,
          textStyle: {
            color: '#d4fff9',
          },
        },
      ],
      geo: {
        type: 'map',
        map: 'henan',
        roam: true,
        label: { show: true },
        emphasis: {
          label: {
            color: '#000',
          },
          itemStyle: {
            areaColor: '#d4fff9',
          },
        },
      },
      series: [
        {
          type: 'map',
          map: 'henan',
          roam: true,
          label: { show: true },
          geoIndex: 0,
          tooltip: {
            formatter: data => {
              // return `
              // <span>
              //   <div>${data.name}</div>
              //   ${ data.value != null ? `安全指数: ${data.value}` : '' }
              // </span>`
              return `
            <span>
              <div>${data.name}</div>
            </span>`;
            },
          },
          data: [
            ...(data.safetyChartAreaInfoList || []).map(v => ({
              name: v.groupName,
              value: v.safetyIndexTotal || 0,
            })),
            // { name: '河南省', value: Math.round(Math.random() * 100) },
            // { name: '湖北省', value: Math.round(Math.random() * 100) },
            // { name: '吉尔吉斯斯坦', value: Math.round(Math.random() * 100) },
          ],
        },
        {
          type: 'scatter',
          coordinateSystem: 'geo',
          itemStyle: {
            shadowBlur: 8,
            shadowColor: '#000',
          },
          tooltip: {
            formatter: e => {
              const data = e.data.ext || {};
              return `
                      <div>
                          <div><strong>${e.name}</strong></div>
                          <div>注册地址: ${data.adress}</div>
                          <div>年度隐患总量: ${data.one}</div>
                          <div>本年重大隐患数量: ${data.three}</div>
                          <div>本年一般隐患数量: ${data.two}</div>
                          <div>年隐患整改率: ${data.four}</div>
                          <div>风险总量: ${data.five}</div>
                          <div>重大风险数量: ${data.six}</div>
                          <div>较大风险数量: ${data.seven}</div>
                          <div>一般风险数量: ${data.eight}</div>
                          <div>低风险数量: ${data.nine}</div>
                      </div>
                  `;
            },
          },
          data: [
            // { name: '周口市社会福利中心', value: [114.689627, 33.59885, { total: Math.round(Math.random() * 100), a: 'a', b: 'b' }] },
            // { name: '淮阳县人民医院', value: [114.89042, 33.755258, { total: Math.round(Math.random() * 100), a: 'a', b: 'b' }] }
            ...(data.safetyChartCompanyInfoList || []).map(v => ({
              name: v.name,
              value: [v.longitude, v.latitude, v.safetyIndex],
              ext: v,
            })),
          ],
        },
      ],
    };
  }
  mapInitEchart(myChart) {
    // 散点点击事件
    myChart.on('click', e => {
      switch (e.seriesType) {
        // 企业小圆点
        case 'scatter': {
          window.open(`${location.origin}/#/data-lsd-v2?companyId=${e.data.ext.companyId}`);
          break;
        }
        // 地图区域
        case 'map': {
          // 在省级地图上打开市级
          // if (this.params.areaLevel == 'province') {
          //   window.open(`${location.origin}/#/data-lsd-jg?code=${e.data.code}`);
          // }
          break;
        }
      }
    });
  }

  /**
   * 不同等级风险数量统计
   */
  loadBtfxdj(differentLevelsRiskNumber) {
    this.btfxdjEchartsOption = {
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)',
        confine: true,
      },
      color: Object.values(this.commonService.color),
      series: [
        {
          name: '访问来源',
          type: 'pie',
          radius: ['30%', '50%'],
          data: [
            { value: differentLevelsRiskNumber.oneRiskCount || 0, name: '重大风险' },
            { value: differentLevelsRiskNumber.twoRiskCount || 0, name: '较大风险' },
            { value: differentLevelsRiskNumber.threeRiskCount || 0, name: '一般风险' },
            { value: differentLevelsRiskNumber.fourRiskCount || 0, name: '低风险' },
          ],
          label: {
            formatter: '{b}\n{c}',
          },
        },
      ],
    };
  }

  /**
   * 年度隐患数量及整改情况统计
   */
  loadjdyhzgqk() {
    if (!this.isReload) {
      this.jdyhzgqkEchartsOption = null;
    }
    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/getYearHiddenTroubleNumber`, params).subscribe(res => {
      if (res.code == 0) {
        this.jdyhzgqkEchartsOption = {
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)',
            confine: true,
          },
          color: ['#fbb72c', '#e57136', '#9c9c9c', '#426bba'],
          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['30%', '50%'],
              data: [
                { value: res.data.generalNotClosedCount || 0, name: '一般隐患未闭环' },
                { value: res.data.generalClosedCount || 0, name: '一般隐患已闭环' },
                { value: res.data.majorNotClosedCount || 0, name: '重大隐患未闭环' },
                { value: res.data.majorClosedCount || 0, name: '重大隐患已闭环' },
              ],
              label: {
                formatter: '{b}\n{c}',
              },
            },
          ],
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }
  /**
   * 各企业风险数量统计数据
   */
  loadFx() {
    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/groupCompany/risk`, params).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.loadFxData(res.data || []);
      } else if (res.code === 100) {
        this.router.navigateByUrl('/passport/login');
        return;
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }
  /**
   * 各企业风险数量统计
   */
  loadFxData(areaRiskNumberList) {
    const riskTypes = [
      { name: '重大风险', field: 'oneRiskNumber' },
      { name: '较大风险', field: 'twoRiskNumber' },
      { name: '一般风险', field: 'threeRiskNumber' },
      { name: '低风险', field: 'fourRiskNumber' },
    ];

    const arr = areaRiskNumberList;

    this.fxEchartsOption = {
      grid: {
        left: 0,
        right: 8,
        containLabel: true,
        bottom: 25,
        top: 30,
      },
      legend: {
        right: 0,
        textStyle: {
          color: '#c7fffe',
        },
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        axisPointer: {
          type: 'shadow',
          label: {
            backgroundColor: '#6a7985',
          },
        },
      },
      xAxis: {
        type: 'category',
        data: arr.map(v => v.groupName),
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: '#c7fffe',
          margin: 2,
        },
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: '#c7fffe',
        },
        splitLine: {
          lineStyle: {
            color: '#044d67',
          },
        },
      },
      dataZoom: [
        {
          type: 'slider',
          xAxisIndex: 0,
          backgroundColor: '#063c58',
          dataBackground: {
            lineStyle: {
              width: 0,
            },
            areaStyle: {
              color: '#0c586c',
              opacity: 1,
            },
          },
          fillerColor: 'rgba(0, 54, 69, 0.58)',
          handleStyle: {
            color: '#00b4cf',
          },
          height: 19,
          borderColor: 'transparent',
          textStyle: {
            color: 'transparent',
          },
          bottom: 0,
        },
        {
          type: 'inside',
          xAxisIndex: 0,
        },
      ],
      color: Object.values(this.commonService.color),
      series: riskTypes.map((type, i) => ({
        data: arr.map(v => v[type.field] || 0),
        name: type.name,
        type: 'bar',
        barMaxWidth: '5',
        stack: '总量',
        label: {
          normal: {
            show: i == 3,
            position: 'top',
            color: '#c7fffe',
            formatter: params => {
              return (
                this.fxEchartsOption &&
                this.fxEchartsOption.series.reduce((total, serie) => total + serie.data[params.dataIndex], 0)
              );
            },
          },
        },
      })),
    };
    console.log(this.fxEchartsOption);
  }
  /**
   * 各企业未闭环的隐患数量统计
   */
  loadWbhyh() {
    if (!this.isReload) {
      this.wbhyhEchartsOption = null;
    }
    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/groupCompany/notClosed`, params).subscribe(res => {
      if (res.code === 0) {
        const riskTypes = [
          { name: '重大隐患', field: 'majorCount' },
          { name: '一般隐患', field: 'generalCount' },
        ];
        const arr = res.data || [];

        this.wbhyhEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe',
            },
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.name),
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2,
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
            },
            splitLine: {
              lineStyle: {
                color: '#044d67',
              },
            },
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0,
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1,
                },
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf',
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent',
              },
              bottom: 0,
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.hiddencolor),
          series: riskTypes.map((type, i) => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barMaxWidth: '5',
            stack: '总量',
            label: {
              normal: {
                show: i == 1,
                position: 'top',
                color: '#c7fffe',
                formatter: params => {
                  return (
                    this.wbhyhEchartsOption &&
                    this.wbhyhEchartsOption.series.reduce((total, serie) => total + serie.data[params.dataIndex], 0)
                  );
                },
              },
            },
          })),
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 各企业本月新增隐患数量统计
   */
  loadxzyh() {
    if (!this.isReload) {
      this.xzyhEchartsOption = null;
    }
    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/groupCompany/monthTheNew`, params).subscribe(res => {
      if (res.code == 0) {
        const riskTypes = [
          { name: '重大隐患', field: 'majorCount' },
          { name: '一般隐患', field: 'generalCount' },
        ];

        const arr = res.data || [];

        this.xzyhEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe',
            },
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.name),
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2,
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
            },
            splitLine: {
              lineStyle: {
                color: '#044d67',
              },
            },
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0,
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1,
                },
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf',
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent',
              },
              bottom: 0,
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.hiddencolor),
          series: riskTypes.map((type, i) => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barMaxWidth: '5',
            stack: '总量',
            label: {
              normal: {
                show: i == 1,
                position: 'top',
                color: '#c7fffe',
                formatter: params => {
                  return (
                    this.xzyhEchartsOption &&
                    this.xzyhEchartsOption.series.reduce((total, serie) => total + serie.data[params.dataIndex], 0)
                  );
                },
              },
            },
          })),
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 近一年隐患变化趋势
   */
  loadynqs() {
    if (!this.isReload) {
      this.ynqsEchartsOption = null;
    }
    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/trendStatistics`, params).subscribe(res => {
      if (res.code == 0) {
        const riskTypes = [
          { name: '重大隐患', field: 'greatCount' },
          { name: '一般隐患', field: 'generalCount' },
        ];

        const arr = res.data.trendStatistics || [];

        this.ynqsEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe',
            },
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.yearAndMonth),
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2,
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
            },
            splitLine: {
              lineStyle: {
                color: '#044d67',
              },
            },
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0,
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1,
                },
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf',
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent',
              },
              bottom: 0,
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.hiddencolor),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'line',
            showSymbol: false,
            hoverAnimation: false,
            lineStyle: {
              width: 1,
            },
          })),
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 退出登录
   */
  logout() {
    // let UserInfo = JSON.parse(window.localStorage.getItem('UserInfo') || '{}');
    // this.http.post(`${baseUrl}/users/loginOut`, { id: UserInfo.user_id }).subscribe(res => {
    const authToken = JSON.parse(window.localStorage.getItem('UserInfo') || '{}').token;
    this.http.get(`${baseUrl}/platform/v1/crmsupervisioncenter/out?token=${authToken}`).subscribe(res => {
      if (res.code == 0) {
        this.tokenService.clear();
        window.localStorage.removeItem('UserInfo');
        this.commonService.loginBeforeUrl = window.location.hash.slice(1);
        window.location.reload();
        // window.open(`${location.origin}/#/data-lsd-jg`);
        // this.commonService.loginBeforeUrl = window.location.hash.slice(1);
        // this.router.navigateByUrl('/passport/login');
        this.message.success('退出成功！');
      }
    });
  }

  /**
   * 安全一张图搜索接口
   */
  getData() {
    const params = {
      ...this.getParams(),
      ...this.searchParams,
    };
    if (params.industry == null) {
      params.industry = '';
    }
    if (params.companyName == null) {
      params.companyName = '';
    }
    this.Aloading = true;
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/riskRanking`, params).subscribe(res => {
      this.Aloading = false;
      if (res.code === 0) {
        this.loadMapData(res.data); // 安全一张图
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }
  // 整改率弹出框
  showModal(type = 'year') {
    if (type === 'year') {
      this.modalTitle = '年度隐患整改率';
    } else if (type === 'month') {
      this.modalTitle = '月度隐患整改率';
    }
    this.getRateNum(type);
    this.isVisible = true;
  }
  // 获取整改完成率
  getRateNum(type = 'year') {
    const params = this.getParams();
    const param = {
      code: params.code,
      areaLevel: params.areaLevel,
      type,
    };
    this.vloading = true;
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/hiddenTroubleRate`, param).subscribe(res => {
      this.vloading = false;
      if (res.code === 0) {
        this.rateNum = res.data || {};
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }
  completionRate() {
    this.rateIsVisible = true;
    // const params = this.getParams();
    // this.http.get<any>(`${hazardUrl}/regulatoryscreens/getRegulatoryIndicators`, params).subscribe(res => {
    //   this.loading = false;
    //   if (res.code === 0) {
    //     this.rateData = res.data.correctionRateRanking || [];
    //   } else {
    //     this.message.error(res.msg || '加载失败');
    //   }
    // });
  }
}
