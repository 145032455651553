import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';
import { baseUrl, CommonService } from '@core';
import { format } from 'date-fns';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CheckBookDetailComponent } from './detail/detail.component';
export interface PersonData {
  code: number;
  data: PersonDataList;
  msg: string;
}
export interface PersonDataList {
  records: [];
  current: number;
  pages: number;
}
export interface HiddenData {
  code: string;
  areaLevel: string;
  matchingType: string;
}
@Component({
  selector: 'app-danger',
  templateUrl: './danger.component.html',
  styleUrls: ['./danger.component.less'],
})
export class V2DangerComponent implements OnInit {
  station_nodes: any = [];
  position_nodes = [];
  personData = []; // 排查人
  constructor(
    private http: _HttpClient,
    private msg: NzMessageService,
    private modal: ModalHelper,
    private router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService,
  ) {
    this.initParam();
    // 加载 管辖部门/单位 tree 数据
    this.loadDepTreeDataCode().subscribe(res => {
      if (res.code === 0) {
        this.station_nodes = res.data || [];
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
    // 加载 区域位置 tree 数据
    this.loadPositionTreeDataCode().subscribe(res => {
      if (res.code === 0) {
        this.position_nodes = res.data || [];
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }
  baseUrl = baseUrl;

  /**
   * table
   */
  loading = false;
  params: any = {};
  searchParams: any = {
    checkResult: 0,
    Mtype: [],
  };
  rows = [];
  pageIndex = 1;
  total = 0;
  pageSize = 10;
  // 地区数据
  areaData = [];
  modelTitle = '年度隐患总量';
  title: any = '';
  companyId: number;
  width = '';
  widthList = [];

  widthMap = {
    '1': '50+150+150+150+150+150+150+150+150+150+150+150+150+150',
  };

  ngOnInit() {
    this.getData();
  }
  getWidth() {
    const widthList = this.widthMap[1].split('+');
    return `${widthList.reduce((prev, current) => prev + +current, 0)}px`;
  }

  getWidthList() {
    const widthList = this.widthMap[1].split('+');
    return widthList.map(w => `${w}px`);
  }
  /**
   * 加载 区域/位置 tree 数据 使用code
   */
  loadPositionTreeDataCode() {
    return this.http
      .get<any>(`${baseUrl}/basics/v1/districts/tree`, { companyId: this.companyId })
      .pipe(
        map(res => {
          // 标识叶子节点
          const recursion = arr => {
            arr.forEach(node => {
              node.key = node.code;
              node.title = node.name;

              if (Array.isArray(node.children) && node.children.length) {
                node.expanded = true;
                recursion(node.children);
              } else {
                node.isLeaf = true;
              }
            });
          };
          recursion(res.data || []);
          return res;
        }),
      );
  }
  /**
   * 加载 管辖部门/单位 tree 数据 使用code
   */
  loadDepTreeDataCode() {
    return this.http
      .get<any>(`${baseUrl}/admin/v1/depts/tree`, { companyId: this.companyId })
      .pipe(
        map(res => {
          // 标识叶子节点
          const recursion = arr => {
            arr.forEach(node => {
              node.key = node.code;
              node.title = node.name;

              if (Array.isArray(node.children) && node.children.length) {
                node.expanded = true;
                recursion(node.children);
              } else {
                node.isLeaf = true;
              }
            });
          };
          recursion(res.data || []);
          return res;
        }),
      );
  }

  /**
   * 获取隐患数据
   * @param reset
   */
  getData(reset: boolean = false) {
    this.width = this.getWidth();
    this.widthList = this.getWidthList();
    if (reset) {
      this.pageIndex = 1;
    }

    if (this.searchParams.searchTime && this.searchParams.searchTime.length > 0) {
      this.searchParams.checkStartTime = format(this.searchParams.searchTime[0], 'YYYY-MM-DD');
      this.searchParams.checkEndTime = format(this.searchParams.searchTime[1], 'YYYY-MM-DD');
    } else {
      this.searchParams.checkStartTime = '';
      this.searchParams.checkEndTime = '';
    }
    if (this.searchParams.reformTime && this.searchParams.reformTime.length > 0) {
      this.searchParams.reformStartTime = format(this.searchParams.reformTime[0], 'YYYY-MM-DD');
      this.searchParams.reformEndTime = format(this.searchParams.reformTime[1], 'YYYY-MM-DD');
    } else {
      this.searchParams.reformStartTime = '';
      this.searchParams.reformEndTime = '';
    }
    if (this.searchParams.potentialAddUserBranch == null) {
      this.searchParams.potentialAddUserBranch = '';
    }
    if (this.searchParams.checkResult == null) {
      this.searchParams.checkResult = '0';
    }
    if (this.searchParams.potentialDepartmentCode == null) {
      this.searchParams.potentialDepartmentCode = '';
    }
    if (this.searchParams.potentialStatus == null) {
      this.searchParams.potentialStatus = '';
    }
    if (this.searchParams.potentialLocationCode == null) {
      this.searchParams.potentialLocationCode = '';
    }

    const searchParams = {
      ...this.searchParams,
      companyId: this.companyId,
      page: this.pageIndex,
      limit: this.pageSize,
    };
    Object.entries(searchParams).forEach(([key, value]) => {
      value === null && delete searchParams[key];
    });
    this.loading = true;
    this.http.get<any>(`${baseUrl}/hazard/v1/regulatoryscreens/hazard/recordPc`, searchParams).subscribe(res => {
      this.loading = false;
      if (res.code == 0) {
        this.rows = res.data.content || [];
        this.rows.forEach(item => {
          if (item.reformTimeLimit && item.potentialStatus == '1') {
            const rtime = new Date(item.reformTimeLimit).getTime();
            const ntime = new Date().getTime();
            if (rtime < ntime) {
              item.color = 'red';
            }
          }
        });
        this.total = res.data.totalElements || 0;
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }
  private initParam() {
    this.route.queryParams.subscribe((params: Params) => {
      this.params = params;
      this.companyId = params.companyId;
      if (params.type) {
        this.searchParams.checkResult = 0;
        if (params.type == 1) {
          this.title = '年度隐患总量列表';
          this.searchParams.potentialStatus = 5;
          this.initYearDate();
        } else if (params.type == 2) {
          this.title = '月度隐患总量列表';
          this.searchParams.potentialStatus = 5;
          this.initMonthDate();
        } else if (params.type == 3) {
          this.title = '当月重大隐患列表';
          this.searchParams.potentialLevel = 2;
          this.searchParams.potentialStatus = 5;
          this.initMonthDate();
        } else if (params.type == 4) {
          this.title = '当月一般隐患列表';
          this.searchParams.potentialLevel = 1;
          this.searchParams.potentialStatus = 5;
          this.initMonthDate();
        } else if (params.type == 5) {
          this.title = '未整改隐患列表';
          this.searchParams.potentialStatus = 6;
        }
        this.searchParams.riskLevel = params.riskLevel;
      }
    });
  }

  // 本月第一天和最后一天日期
  initMonthDate() {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const day = new Date(year, month, 0);
    const searchTime = [];
    searchTime[0] = year + '-' + (month < 10 ? '0' + month : month) + '-01';
    searchTime[1] = year + '-' + (month < 10 ? '0' + month : month) + '-' + day.getDate();
    this.searchParams.searchTime = searchTime;
  }

  initYearDate() {
    const year = new Date().getFullYear();
    const searchTime = [];
    searchTime[0] = year + '-' + '01-01';
    searchTime[1] = year + '-' + '12-31';
    this.searchParams.searchTime = searchTime;
  }
  /**
   * 根据部门code选人
   */

  changeDeptcode(code: string) {
    this.searchParams.potentialAddUserId = [];
    const searchParams = {
      deptCode: code,
      company: this.companyId,
      current: 1,
      size: 1000,
    };
    if (code) {
      this.http.get<PersonData>(`${baseUrl}/admin/v1/users`, searchParams).subscribe(res => {
        if (res.code == 0) {
          this.personData = res.data.records || [];
        } else {
          this.msg.error(res.msg || '加载失败');
        }
      });
    } else {
      this.personData = [];
    }
  }
  // 单个文件下载并改名字
  onStart(e) {
    this.http
      .get(e.potentialInspectInfo.reformNoticeFile.urlPath, this.searchParams, {
        responseType: 'blob',
        observe: 'response',
      })
      .subscribe((res: any) => {
        if (res.body.code == 1) {
          this.loading = false;
          this.msg.error('导出不成功!');
          return;
        }

        try {
          const link = document.createElement('a');
          const blob = new Blob([<any>res.body]);
          let fileNmae = e.potentialInspectInfo.reformNoticeFile.fileName;
          try {
            fileNmae = decodeURIComponent(res.headers.get('Content-Disposition').split('filename=')[1]);
          } catch (error) { } //在有的浏览器中取不到响应头
          link.setAttribute('href', window.URL.createObjectURL(blob));
          link.setAttribute('download', fileNmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          this.msg.error(error.toString());
        }
      });
  }
  time(e) {
    if (JSON.stringify(e) == '[]') {
      this.searchParams.searchTime = null;
      this.searchParams.checkStartTime = '';
      this.searchParams.checkEndTime = '';
    } else {
      this.searchParams.checkStartTime = format(this.searchParams.searchTime[0], 'YYYY-MM-DD');
      this.searchParams.checkEndTime = format(this.searchParams.searchTime[1], 'YYYY-MM-DD');
    }
  }
  reformtime(e) {
    if (JSON.stringify(e) == '[]') {
      this.searchParams.reformTime = null;
      this.searchParams.reformStartTime = '';
      this.searchParams.reformEndTime = '';
    } else {
      this.searchParams.reformStartTime = format(this.searchParams.reformTime[0], 'YYYY-MM-DD');
      this.searchParams.reformEndTime = format(this.searchParams.reformTime[1], 'YYYY-MM-DD');
    }
  }
  onOpenViewDialog(data: any) {
    this.modal.create(CheckBookDetailComponent, { i: data }, {
      size: 1100,
      modalOptions: {
        nzMaskClosable: false,
      },
    },
    ).subscribe(() => {
      this.getData()
    });
  }
}
