import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-quota-great',
  templateUrl: './layout-quota-great.component.html',
  styles: []
})
export class LayoutQuotaGreatComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
