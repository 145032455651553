import { Component, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-amap-full',
  templateUrl: './amap-full.component.html',
  styleUrls: ['./amap-full.component.less']
})
export class AmapFullComponent implements OnInit {
  outHeight: any = 0;
  companyId: string = '';
  constructor(private route: ActivatedRoute,) {
    route.queryParams.subscribe(queryParams => {
      this.companyId = queryParams.companyId;
    });
  }

  ngOnInit() {




    this.outHeight = window.innerHeight - 48 + 'px';
    fromEvent(window, 'resize').subscribe(($event) => {
      this.outHeight = window.innerHeight - 48 + 'px';
    });
  }

}
