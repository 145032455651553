import { Injectable } from '@angular/core';

import {
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  Resolve,
  CanLoad,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  Router,
} from '@angular/router';

import { Observable, of, forkJoin } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TokenValidService {
  constructor(public router: Router) {}

  tokenValid() {
    // const beforeUrl = window.location.hash.slice(1);
    // window.localStorage.setItem('loginBeforeUrl', beforeUrl)

    const userInfo = JSON.parse(window.localStorage.getItem('UserInfo') || '{}');
    console.log(userInfo);
    if (!userInfo.token) {
      window.localStorage.removeItem('UserInfo');
      this.router.navigate(['/passport/login']);
      return false;
    } else {
      return true;
    }
  }

  /**
   * 激活守卫
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.tokenValid();
  }

  /**
   * 子路由激活守卫
   * @param route
   * @param state
   */
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.tokenValid();
  }
}
