import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { map } from 'rxjs/operators';
import { baseUrl } from '@core';

import { ActivatedRoute, Params, Router } from '@angular/router';
@Component({
  selector: 'app-jtrisk',
  templateUrl: './jtrisk.component.html',
  styleUrls: ['./jtrisk.component.less'],
})

export class JtRiskComponent implements OnInit {
  window = window;
  title = '风险列表';
  Jttype = 0;
  /**
   * table
   */
  tableHeight = '';
  loading = false;
  searchParams: any = {
    evaluateType: 1,
    riskLevel: ''
  };
  rows = [];
  pageIndex = 1;
  total = 0;
  pageSize = 5;

  // 地区数据
  areaData = [];
  params: any = {};
  companyId: number;
  /**
   * 对象类型
   */
  evaluateTypes = [
    { label: '设备设施', value: 1 },
    { label: '作业活动', value: 2 },
    { label: '工艺节点', value: 3 },
    { label: '作业场所', value: 4 },
  ];
  get evaluateTypeMap() {
    let obj = {};
    this.evaluateTypes.forEach(item => obj[item.value] = item.label);
    return obj;
  }

  /**
   * 风险等级
   */
  xycsEmRiskLevels = [
    { label: '重大风险', value: 1 },
    { label: '较大风险', value: 2 },
    { label: '一般风险', value: 3 },
    { label: '低风险', value: 4 },
  ];

  width = '';
  widthList = [];

  widthMap = {
    '1': '30+150+150+150+150+150+150+100+100+150+200+200+200+200+200+100+120+120+160+120+150+100+100',
    '2': '30+150+150+150+150+150+150+150+200+150+200+200+200+200+150+120+120+160+120+120+150+100',
    '3': '30+100+100+150+150+150+150+150+150+150+150+100+100+150+200+200+200+200+200+100+120+160+120+120+150+100',
    '4': '30+150+150+150+150+150+150+150+200+150+200+200+200+200+150+120+120+160+120+120+150+100',
  };
  get xycsEmRiskLevelMap() {
    const obj = {};
    this.xycsEmRiskLevels.forEach(item => obj[item.value] = item.label);
    return obj;
  }

  /**
   * 管控层级
   */
  controllerLevelList = [];
  get controllerLevelMap() {
    const obj = {};
    this.controllerLevelList.forEach(item => obj[item.value] = item.label);
    return obj;
  }
  constructor(
    private msg: NzMessageService,
    private http: _HttpClient,
  ) {
    this.tableHeight = `${document.body.getBoundingClientRect().height - 195 - 60 - 60}px`;
  }

  async ngOnInit() {
    await this.getList();// 管控层级
    this.search();
  }


  getList() {
    return this.http.get<any>(`${baseUrl}/admin/v1/organization/custom`, { company: this.companyId }).toPromise().then(res => {
      if (res.code == 0) {
        res.data.forEach(item => {
          const data = { label: item.customTypeName, value: item.controlLevel };
          this.controllerLevelList.push(data)
        })

      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }
  getWidth() {
    const widthList = this.widthMap[this.searchParams.evaluateType].split('+');
    return `${widthList.reduce((prev, current) => prev + +current, 0)}px`;
  }

  getWidthList() {
    const widthList = this.widthMap[this.searchParams.evaluateType].split('+');
    return widthList.map(w => `${w}px`);
  }

  /**
   * 加载数据
   * @param reset 起始页
   */
  search(reset: boolean = false) {
    this.width = this.getWidth();
    this.widthList = this.getWidthList();

    if (reset) {
      this.pageIndex = 1;
    }
    const searchParams = {
      ...this.searchParams,
      companyId: this.companyId,
      riskLevel:this.Jttype == 0 ? this.searchParams.riskLevel : 1,
      type:this.Jttype,
      page: this.pageIndex,
      limit: this.pageSize
    };

    Object.entries(searchParams).forEach(([key, value]) => { value === null && delete searchParams[key]; });
    this.loading = true;
    this.http.get<any>(`${baseUrl}/hazard/v1/regulatoryscreens/company/risk/detail/list`, searchParams).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.rows = res.data.content || [];
        this.total = res.data.totalElements || 0;
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }

}
