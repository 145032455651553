import { Component, OnInit } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { CommonService, baseUrl } from '@core';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styles: [],
})
export class CompanyComponent implements OnInit {
  loading = false;
  searchParams: any = {
    industryFilter: '',
    classifyIdFilter: '',
  };
  basePar: any = {};
  total = 0;
  pageIndex = 1;
  pageSize = 20;
  isView = false;
  data;
  tradeList = [];
  classifyList = [];
  companyData = [];
  noticeId = null; // 公告id

  // 省市区
  provinceList = [];
  cityList = [];
  townList = [];
  districtList = [];
  villageList = [];

  // 全选
  rows = [];
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  mapOfCheckedId: { [key: string]: boolean } = {};
  numberOfChecked = 0;
  checkedRows = [];

  checkedIds: string[];

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.rows
      .filter(item => !item.disabled)
      .every(item => this.mapOfCheckedId[item.companyId]);

    this.isIndeterminate =
      this.rows.filter(item => !item.disabled).some(item => this.mapOfCheckedId[item.companyId]) &&
      !this.isAllDisplayDataChecked;

    this.checkedIds = Object.entries(this.mapOfCheckedId)
      .filter(([companyId, checked]) => checked)
      .map(([companyId]) => companyId);
    this.numberOfChecked = this.checkedIds.length;

    this.rows.forEach(row => {
      const currentIndex = this.checkedRows.findIndex(v => v.companyId === row.companyId);
      if (this.mapOfCheckedId[row.companyId]) {
        // tslint:disable-next-line: no-unused-expression
        currentIndex === -1 && this.checkedRows.push(row);
      } else {
        // tslint:disable-next-line: no-unused-expression
        currentIndex !== -1 && this.checkedRows.splice(currentIndex, 1);
      }
    });

    this.checkedRows = [...this.checkedRows];
  }

  checkAll(value: boolean): void {
    this.rows.filter(item => !item.disabled).forEach(item => (this.mapOfCheckedId[item.companyId] = value));
    this.refreshStatus();
  }

  constructor(
    private message: NzMessageService,
    private mod: NzModalRef,
    public http: _HttpClient,
    public commonService: CommonService,
  ) {}

  ngOnInit() {
    if (this.basePar)
      if (this.companyData.length > 0) {
        this.companyData.forEach(row => {
          this.mapOfCheckedId[row.companyId] = true;
          this.checkedRows.push(row);
        });
      }

    if (this.isView) {
      this.basePar.noticeId = this.noticeId;
    }
    // 加载省

    this.getArea();
    this.getTrade();
    this.getClassify();
    // this.search();
  }

  /**
   * load
   * @ param reset
   */
  getTrade() {
    const url = this.isView
      ? '/hazard/v1/regulatoryscreens/notice/comboBox/selected/industry'
      : '/hazard/v1/regulatoryscreens/notice/comboBox/industry';
    this.http.get<any>(`${baseUrl}${url}`, this.basePar).subscribe(res => {
      if (res.code === 0) {
        this.tradeList = res.data || [];
      }
    });
  }

  getClassify() {
    const url = this.isView
      ? '/hazard/v1/regulatoryscreens/notice/comboBox/selected/classify'
      : '/hazard/v1/regulatoryscreens/notice/comboBox/classify';
    this.http.get<any>(`${baseUrl}${url}`, this.basePar).subscribe(res => {
      if (res.code === 0) {
        this.classifyList = res.data || [];
      }
    });
  }

  getArea() {
    const code = this.basePar.code;
    switch (this.basePar.areaLevel) {
      case 'province':
        this.searchParams.provinceFilter = code;
        this.provinceList = [{ name: this.basePar.levelName, code }];
        this.searchAddressChange({ dataField: 'cityList', type: 'city' }, code, true);
        break;
      case 'city':
        this.searchParams.cityFilter = code;
        this.cityList = [{ name: this.basePar.levelName, code }];
        this.searchAddressChange({ dataField: 'districtList', type: 'district' }, code, true);
        break;
      case 'district':
        this.searchParams.districtFilter = code;
        this.districtList = [{ name: this.basePar.levelName, code }];
        this.searchAddressChange({ dataField: 'townList', type: 'town' }, code, true);
        break;
      case 'town':
        this.searchParams.townFilter = code;
        this.townList = [{ name: this.basePar.levelName, code }];
        this.searchAddressChange({ dataField: 'villageList', type: 'village' }, code, true);
        break;
      case 'village':
        this.searchParams.villageFilter = code;
        break;
      default:
        this.searchParams.provinceFilter = '';
    }
    this.search();
  }

  /**
   * load
   * @ param reset
   */
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    const searchParams = {
      ...this.searchParams,
      limit: this.pageSize,
      page: this.pageIndex,
      ...this.basePar,
    };
    Object.entries(searchParams).forEach(([key, value]) => {
      if (value === null) {
        delete searchParams[key];
      }
    });

    this.loading = true;
    const url = this.isView
      ? '/hazard/v1/regulatoryscreens/notice/release/range'
      : '/hazard/v1/regulatoryscreens/notice/choice/company';
    this.http.get<any>(`${baseUrl}${url}`, searchParams).subscribe(res => {
      if (res.code === 0) {
        this.rows = res.data.records || [];
        this.total = res.data.total || 0;
      } else {
        this.message.error(res.msg || '加载失败');
      }
      this.loading = false;
    });
  }

  submit() {
    this.mod.destroy(this.checkedRows);
  }

  close() {
    this.mod.destroy();
  }

  /**
   * 地址选择
   * @ param next = { dataField: 数据源, type: 类型 }
   * @ param code
   */
  searchAddressChange(next, code, clean = false) {
    this.commonService.getAddress(next.type, code).subscribe(list => {
      this[next.dataField] = list;
    });

    if (clean) {
      switch (next.type) {
        case 'city':
          this.searchParams.cityFilter = null;
          this.searchParams.districtFilter = null;
          this.searchParams.townFilter = null;
          this.searchParams.villageFilter = null;
          break;
        case 'district':
          this.searchParams.districtFilter = null;
          this.searchParams.townFilter = null;
          this.searchParams.villageFilter = null;
          break;
        case 'town':
          this.searchParams.townFilter = null;
          this.searchParams.villageFilter = null;
          break;
        case 'village':
          this.searchParams.villageFilter = null;
          break;
      }
    }
  }
}
