import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';
import { baseUrl } from '@core';
import { CompanyComponent } from '../company/company.component';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.less'],
})
export class DetailComponent implements OnInit {
  id;
  dataSource: any = {};
  basePar = {};
  listOfOption = ['企业负责人', '安全部门负责人', '企业超级管理员'];
  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public ngHttp: HttpClient,
    private mod: ModalHelper,
  ) {}

  ngOnInit() {
    this.http
      .get<any>(`${baseUrl}/hazard/v1/regulatoryscreens/notice/detail`, { id: this.id })
      .subscribe(res => {
        if (res.code === 0) {
          res.data.supervisionCenterScopePeople = res.data.supervisionCenterScopePeople
            .map(item => this.listOfOption[item - 1])
            .join(',');
          this.dataSource = res.data || {};
        }
      });
  }

  /**
   * 打开 选择企业 对话框
   */
  openCompany() {
    this.mod
      .create(
        CompanyComponent,
        { basePar: this.basePar, isView: true, noticeId: this.id },
        {
          size: 1400,
          modalOptions: {
            nzMaskClosable: false,
          },
        },
      )
      .subscribe(res => {});
  }
}
