import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { DomSanitizer } from '@angular/platform-browser';

import { _HttpClient } from '@delon/theme';
import { baseUrl, domain, BZbaseUrl, YHbaseUrl, XinbaseUrl } from '@core';
import { CommonService } from '@core';

import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';

import {
  ActivatedRoute,
  Params,
  Router,
  ActivationEnd,
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
} from '@angular/router';

import * as G2 from '@antv/g2';
import * as moment from 'moment';

const hazardUrl = `${domain}/hazard/v1`;
const basicsUrl = `${domain}/basics/v1`;

@Component({
  selector: 'app-data-lsd-v2',
  templateUrl: './data-lsd-v2.component.html',
  styleUrls: ['./data-lsd-v2.component.scss'],
})
export class DataLsdV2Component implements OnInit {
  window = window;

  company: string;
  // 企业id
  companyId: string;
  systemNotice_carousel_autoPlay = true;
  risk_gongshi_carousel_autoPlay = true;
  yinhuan_gongshi_carousel_autoPlay = true;
  risk1_carousel_autoPlay = true;
  risk2_carousel_autoPlay = true;
  yh1_carousel_autoPlay = true;
  yh2_carousel_autoPlay = true;

  // logo
  logoData: any = {};
  // 日期时间
  datetime = '';
  // 是否全屏
  isFullscreen = false;
  // 统计数量
  totalData: any = {};
  // 通知公告
  systemNotice = [];
  // 重大风险公示
  majorNotificationList = [];
  // 重大隐患公示
  majorHiddenDangerPublicity = [];

  /**
   * echartsOption
   */
  // <风险统计>:
  // 风险按区域统计
  qyEchartsOption = null;
  // 风险按管辖部门统计
  bmEchartsOption = null;

  // 风险点统计(设备设施)
  ssEchartsOption = null;
  // 风险点统计(作业活动)
  zyEchartsOption = null;
  // 风险点统计(工艺节点)
  gyEchartsOption = null;

  // <隐患统计>:
  // 按责任单位统计
  zrdwEchartsOption = null;
  // 按区域统计
  qyyhEchartsOption = null;
  // 按类别统计
  lbyhEchartsOption = null;

  // 隐患变化趋势
  yhqsEchartsOption = null;
  // 弹出框
  APisVisible = false;
  isVisible = false;
  modalTitle = '专家隐患排查历史报告记录';
  modalTips = '专家隐患排查安全指数取值最新的排查报告';
  // 报告数据
  listOfMapData = [];
  ReportData = [];
  pageIndex = 1;
  pageSize = 10;
  total = 0;
  loading = false;
  companyName = null;

  APViewData;
  APName;
  APViewisVisible=false;
  APScore;
  BZScore;
  YHScore;


  type = 1;
  constructor(
    public sanitizer: DomSanitizer,
    private message: NzMessageService,
    private http: _HttpClient,
    private eleMentRef: ElementRef,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.company = params.c;
      this.companyId = params.companyId;
    });
    this.load_screen();
    this.setDateTime();
    this.load_companyscreens();
    this.load_systemNotice();
    this.load_majorNotification();
    this.load_majorHiddenDangerPublicity();

    this.load_checkItemLocationStatistics();
    this.load_checkItemDepartmentStatistics();

    this.load_objectStatistics(1);
    this.load_objectStatistics(2);
    this.load_objectStatistics(3);

    this.load_chartStatistics('area');
    this.load_chartStatistics('category');
    this.load_chartStatistics('duty');

    this.load_trendStatistics();
    // 添加 轮播图 播放暂停 事件
    setTimeout(() => {
      Array.from(this.eleMentRef.nativeElement.querySelectorAll('[autoPlay_field]')).forEach(
        (carousel: HTMLElement) => {
          const autoPlay_field = carousel.getAttribute('autoPlay_field');
          carousel.addEventListener('mouseenter', e => {
            this[autoPlay_field] = false;
          });
          carousel.addEventListener('mouseleave', e => {
            this[autoPlay_field] = true;
          });
        },
      );
    }, 0);
  }

  // 全屏切换
  onSwitchFullscreen() {
    // 进入全屏
    const requestFullScreen = () => {
      const de = document.documentElement as any;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen();
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen();
      }
    };
    // 退出全屏
    const exitFullscreen = () => {
      const de = document as any;
      if (de.exitFullscreen) {
        de.exitFullscreen();
      } else if (de.mozCancelFullScreen) {
        de.mozCancelFullScreen();
      } else if (de.webkitCancelFullScreen) {
        de.webkitCancelFullScreen();
      }
    };

    if (this.isFullscreen) {
      exitFullscreen();
    } else {
      requestFullScreen();
    }
    this.isFullscreen = !this.isFullscreen;
  }

  // 获取请求参数
  getParams() {
    return {
      // access_token: JSON.parse(window.localStorage.getItem('_token') || '{}').token
      // access_token: JSON.parse(window.localStorage.getItem('UserInfo') || '{}').token,
      companyId: this.companyId,
    };
  }

  // 设置日期时间
  setDateTime() {
    const dayMap = {
      0: '星期日',
      1: '星期一',
      2: '星期二',
      3: '星期三',
      4: '星期四',
      5: '星期五',
      6: '星期六',
    };
    this.datetime = moment().format('YYYY-MM-DD HH:mm:ss ') + dayMap[moment().day()];
    setInterval(() => {
      this.datetime = moment().format('YYYY-MM-DD HH:mm:ss ') + dayMap[moment().day()];
    }, 1000);
  }

  // 加载 logo
  load_screen() {
    const params = this.getParams();
    const $that = this;
    this.http.get<any>(`${basicsUrl}/companys/screen`, params).subscribe(res => {
      if (res.code == 0) {
        this.logoData = res.data || {};
        // this.logoData.companyLogo = 'https://s2.ax1x.com/2019/08/05/e2H3cR.jpg';
        if (this.logoData.companyLogo) {
          this.logoData.companyLogo = this.sanitizer.bypassSecurityTrustStyle('url(' + this.logoData.companyLogo + ')');
        }
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 统计数量
  load_companyscreens() {
    /**
     * 隐患
     */
    let params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens`, params).subscribe(res => {
      if (res.code == 0) {
        this.totalData = {
          ...this.totalData,
          ...res.data,
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });

    /**
     * 风险
     */
    params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/risknum`, params).subscribe(res => {
      if (res.code == 0) {
        this.totalData = {
          ...this.totalData,
          ...res.data,
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 通知公告
  load_systemNotice() {
    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/systemNotice`, params).subscribe(res => {
      if (res.code == 0) {
        this.systemNotice = res.data.notice || [];
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 重大风险公示
  load_majorNotification() {
    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/majorNotification`, params).subscribe(res => {
      if (res.code == 0) {
        this.majorNotificationList = res.data.riskNotification || [];
        // 取前10个
        this.majorNotificationList = this.majorNotificationList.slice(0, 10);
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 重大隐患公示
  load_majorHiddenDangerPublicity() {
    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/majorHiddenDangerPublicity`, params).subscribe(res => {
      if (res.code == 0) {
        this.majorHiddenDangerPublicity = res.data.hiddenDangerNotification || [];
        // 取前10个
        this.majorHiddenDangerPublicity = this.majorHiddenDangerPublicity.slice(0, 10);
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 风险按区域统计
  load_checkItemLocationStatistics() {
    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/checkItemLocationStatistics`, params).subscribe(res => {
      if (res.code == 0) {
        const riskTypes = [
          { name: '重大风险', field: 'greatRiskCount' },
          { name: '较大风险', field: 'relativeRiskCount' },
          { name: '一般风险', field: 'generalRiskCount' },
          { name: '低风险', field: 'lowRiskCount' },
        ];

        const arr = res.data.riskLocationStatistics || [];

        this.qyEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe',
            },
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.groupName),
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2,
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
            },
            splitLine: {
              lineStyle: {
                color: '#044d67',
              },
            },
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0,
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1,
                },
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf',
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent',
              },
              bottom: 0,
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.color),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barMaxWidth: '5',
          })),
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 风险按管辖部门统计
  load_checkItemDepartmentStatistics() {
    const params = this.getParams();
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/checkItemDepartmentStatistics`, params).subscribe(res => {
      if (res.code == 0) {
        const riskTypes = [
          { name: '重大风险', field: 'greatRiskCount' },
          { name: '较大风险', field: 'relativeRiskCount' },
          { name: '一般风险', field: 'generalRiskCount' },
          { name: '低风险', field: 'lowRiskCount' },
        ];

        const arr = res.data.riskDepartmentStatistics || [];

        this.bmEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe',
            },
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.groupName),
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2,
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
            },
            splitLine: {
              lineStyle: {
                color: '#044d67',
              },
            },
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0,
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1,
                },
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf',
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent',
              },
              bottom: 0,
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.color),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barWidth: '5',
          })),
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 风险点统计
  load_objectStatistics(type) {
    const params = { ...this.getParams(), type };
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/objectStatistics`, params).subscribe(res => {
      if (res.code == 0) {
        let option = '';
        switch (type) {
          case 1: {
            option = 'ssEchartsOption';
            break;
          }
          case 2: {
            option = 'zyEchartsOption';
            break;
          }
          case 3: {
            option = 'gyEchartsOption';
            break;
          }
        }

        const arr = res.data.objectStatistics || [];

        this[option] = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe',
            },
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.typeName),
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2,
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
            },
            splitLine: {
              lineStyle: {
                color: '#044d67',
              },
            },
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0,
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1,
                },
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf',
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent',
              },
              bottom: 0,
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          series: [
            {
              data: arr.map(v => v.count || 0),
              type: 'bar',
              barWidth: '5',
            },
          ],
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 隐患统计
  load_chartStatistics(classifiedType) {
    const params = { ...this.getParams(), classifiedType };
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/chartStatistics`, params).subscribe(res => {
      if (res.code == 0) {
        let option = '';
        let resField = '';
        switch (classifiedType) {
          case 'area': {
            option = 'qyyhEchartsOption';
            resField = 'areaStatistics';
            break;
          }
          case 'duty': {
            option = 'zrdwEchartsOption';
            resField = 'dutyUnitStatistics';
            break;
          }
          case 'category': {
            option = 'lbyhEchartsOption';
            resField = 'potentialCategoryStatistics';
            break;
          }
        }

        const riskTypes = [
          { name: '重大隐患', field: 'greatCount' },
          { name: '一般隐患', field: 'generalCount' },
        ];

        const arr = res.data[resField] || [];

        this[option] = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe',
            },
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.groupName),
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2,
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
            },
            splitLine: {
              lineStyle: {
                color: '#044d67',
              },
            },
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0,
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1,
                },
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf',
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent',
              },
              bottom: 0,
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.hiddencolor),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'bar',
            barMaxWidth: '5',
          })),
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  // 加载 隐患变化趋势
  load_trendStatistics() {
    const params = { ...this.getParams() };
    this.http.get<any>(`${hazardUrl}/regulatoryscreens/company/trendStatistics`, params).subscribe(res => {
      if (res.code == 0) {
        const riskTypes = [
          { name: '重大隐患', field: 'greatCount' },
          { name: '一般隐患', field: 'generalCount' },
        ];

        const arr = res.data.trendStatistics || [];

        // arr = [{"greatCount":81,"generalCount":74,"yearAndMonth":"1月"},{"greatCount":77,"generalCount":20,"yearAndMonth":"2月"},{"greatCount":59,"generalCount":67,"yearAndMonth":"3月"},{"greatCount":44,"generalCount":78,"yearAndMonth":"4月"},{"greatCount":66,"generalCount":35,"yearAndMonth":"5月"},{"greatCount":54,"generalCount":79,"yearAndMonth":"6月"},{"greatCount":49,"generalCount":45,"yearAndMonth":"7月"},{"greatCount":40,"generalCount":91,"yearAndMonth":"8月"},{"greatCount":8,"generalCount":94,"yearAndMonth":"9月"},{"greatCount":80,"generalCount":32,"yearAndMonth":"10月"},{"greatCount":43,"generalCount":55,"yearAndMonth":"11月"},{"greatCount":9,"generalCount":69,"yearAndMonth":"12月"}];

        this.yhqsEchartsOption = {
          grid: {
            left: 0,
            right: 8,
            containLabel: true,
            bottom: 25,
            top: 30,
          },
          legend: {
            right: 0,
            textStyle: {
              color: '#c7fffe',
            },
            data: [
              {
                name: '重大隐患',
                icon: 'roundRect',
              },
              {
                name: '一般隐患',
                icon: 'roundRect',
              },
            ],
          },
          tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
              type: 'shadow',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          xAxis: {
            type: 'category',
            data: arr.map(v => v.yearAndMonth),
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
              margin: 2,
            },
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#c7fffe',
            },
            splitLine: {
              lineStyle: {
                color: '#044d67',
              },
            },
          },
          dataZoom: [
            {
              type: 'slider',
              xAxisIndex: 0,
              backgroundColor: '#063c58',
              dataBackground: {
                lineStyle: {
                  width: 0,
                },
                areaStyle: {
                  color: '#0c586c',
                  opacity: 1,
                },
              },
              fillerColor: 'rgba(0, 54, 69, 0.58)',
              handleStyle: {
                color: '#00b4cf',
              },
              height: 19,
              borderColor: 'transparent',
              textStyle: {
                color: 'transparent',
              },
              bottom: 0,
            },
            {
              type: 'inside',
              xAxisIndex: 0,
            },
          ],
          color: Object.values(this.commonService.hiddencolor),
          series: riskTypes.map(type => ({
            data: arr.map(v => v[type.field] || 0),
            name: type.name,
            type: 'line',
            showSymbol: false,
            hoverAnimation: false,
            lineStyle: {
              width: 1,
            },
          })),
        };
      } else {
        this.message.error(res.msg || '加载失败');
      }
    });
  }

  /**
   * 退出登录
   */
  logout() {
    this.http.delete(`${baseUrl}/auth/token/logout`).subscribe(res => {
      document.cookie = `token=; domain=.xinanyun.cn`;
      document.cookie = `tenant_id=; domain=.xinanyun.cn`;
      this.tokenService.clear();
      this.commonService.loginBeforeUrl = window.location.hash.slice(1);
      if (this.company == 'mt1d') {
        // 如果是煤田一队进入， 退出返回到煤田一队登录页
        this.router.navigateByUrl('/demo/data-lsd-login');
      } else {
        this.router.navigateByUrl(this.tokenService.login_url);
      }
      this.message.success('退出成功！');
    });
  }
  goto() {
    this.http.get(`${baseUrl}/admin/v1/member/supervise/login?company=${this.companyId}`).subscribe(res => {
      if (res.code === 0) {
        // document.cookie = `token=${res.access_token}; domain=.xinanyun.cn`;
        document.cookie = `tenant_id=${res.tenant_id}; domain=.xinanyun.cn`;
        // document.cookie = `token=${res.access_token}; domain=localhost`;
        window.location.href = `${XinbaseUrl}/#/dashboard`;
      } else {
        this.message.error(res.msg);
      }
    });
  }
  /**
   * 获取专家隐患排查安全指数
   */
  load_score() {
    this.http.get(`${YHbaseUrl}/api/XAYToolApi/score`, { company_name: this.logoData.fullName }).subscribe(res => {
      if (res.status === '1') {
        this.YHScore = res.data || 0;
      }
    });
  }
  /**
   * 获取标准化评分
   */
  load_BZscore() {
    this.http.get(`${BZbaseUrl}/api/XAYToolApi/score`, { company_name: this.logoData.fullName }).subscribe(res => {
      if (res.status === '1') {
        this.BZScore = res.data || 0;
      }
    });
  }
  /**
   * 打开工具报告列表
   */
  openReport(type = 1) {
    this.isVisible = true;
    switch (type) {
      case 1:
        this.modalTitle = '专家隐患排查历史报告记录';
        this.modalTips = '专家隐患排查安全指数取值最新的排查报告';
        break;
      case 2:
        this.modalTitle = '专家标准化评审历史报告记录';
        this.modalTips = '安全标准化评分取值最新一次的评审报告';
        break;
    }
    this.getData(type);
  }
  getData(type = 1) {
    let url = `${YHbaseUrl}/api/XAYToolApi/historyReportList`;
    switch (type) {
      case 1:
        url = `${YHbaseUrl}/api/XAYToolApi/historyReportList`;
        break;
      case 2:
        url = `${BZbaseUrl}/api/XAYToolApi/historyReportList`;
        break;
    }
    this.type = type;
    const $that = this;
    // 活该活该计划计划
    this.http.get(`${url}?company_name=${this.logoData.fullName}`).subscribe(res => {
      if (res.status === '1') {
        this.ReportData = res.data || [];
        this.ReportData.forEach(item => {
          if ($that.type == 1) {
            if (item.is_update == 0) {
              item.stagePath =
                `http:\u002f\u002fdocs.xinanyun.cn\u002fop\u002fview.aspx?src=` +
                encodeURIComponent(`${YHbaseUrl}/${item.file_url}`);
            } else {
              item.stagePath = `${YHbaseUrl}/${item.fileUrl}`;
            }
            item.domainUrl = `${YHbaseUrl}`;
          } else {
            if (item.is_update == 0) {
              item.stagePath =
                `http:\u002f\u002fdocs.xinanyun.cn\u002fop\u002fview.aspx?src=` +
                encodeURIComponent(`${BZbaseUrl}/${item.file_url}`);
            } else {
              item.stagePath = `${BZbaseUrl}/${item.fileUrl}`;
            }
            item.domainUrl = `${BZbaseUrl}`;
          }

          console.log(`${BZbaseUrl}/${item.file_url}`);
        });
      } else {
        this.message.error('获取报告失败');
      }
    });
  }

  /**
   * 获取安评过控不符合项
   */
  load_APscore() {
    this.http.get(`${domain}/platform/v1/apProject/notConformCount`, { company: this.companyId }).subscribe(res => {
      if (res.code === 0) {
        this.APScore = res.data || 0;
      } else {
        this.message.error('获取不符合项失败');
      }
    });
  }
  /**
   * 打开安评国控报告列表
   */
  openReportAP() {
    this.APisVisible = true;
    this.getDataAP();
  }
  /**
   * 获取安评国控的报告数据
   */
  getDataAP(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    // const url = `${domain}/platform/v1/apProject/project?company=161987`;
    const url = `${domain}/platform/v1/apProject/project?company=${this.companyId}`;
    const params = {
      page: this.pageIndex,
      pageSize: this.pageSize,
    };
    this.http.get(`${url}`, params).subscribe(res => {
      if (res.code === 0) {
        this.listOfMapData = res.data.list || [];
        this.total = res.data.total || 0;
      } else {
        this.message.error('获取不符合项失败');
      }
    });
  }
  /**
   * 获取安评报告预览
   * @param projectId
   */
  openReportView(projectId) {
    this.APViewisVisible = true;
    this.http.get(`${domain}/platform/v1/apProject/notConform?projectId=${projectId}`).subscribe(res => {
      if (res.code === 0) {
        this.APName = res.data.Name;
        this.APViewData = res.data.allNotIsCornList || [];
      } else {
        this.message.error('获取不符合项失败');
      }
    });
  }
}
