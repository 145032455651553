/**
 * 工具服务
 */

import { Injectable } from '@angular/core';
// import * as moment from 'moment';

import { environment } from '../../environments/environment';

import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  // 当前登录用户的id
  get currentUserId() {
    return JSON.parse(window.localStorage._token || '{}').user_id;
  }

  constructor(private http: HttpClient) {}

  get path() {
    // 公司id
    const tenant_id = JSON.parse(window.localStorage.getItem('_token') || '{}').tenant_id;
    return `${tenant_id}/${+new Date()}_${this.selectRandom()}`;
  }
  get expirationTime() {
    // 20年(秒)
    return 630720000;
  }

  // 是否隐藏模块菜单
  isHideModuleSidebar = true;
  // 是否在首页
  isDashboard = false;

  // 是否使用服务器菜单
  useServerMenu = true;
  // 模块列表菜单
  moduleListMenu = [];

  // 登录后需要打开的页面
  loginBeforeUrl: string = null;
  // 行业
  hyly = [
    '农业',
    '林业',
    '畜牧业',
    '渔业',
    '农、林、牧、渔服务业',
    '煤炭开采和洗选业',
    '石油和天然气开采业',
    '黑色金属矿采选业',
    '有色金属矿采选业',
    '非金属矿采选业',
    '开采辅助活动',
    '其他采矿业',
    '农副食品加工业',
    '食品制造业',
    '酒、饮料和精制茶制造业',
    '烟草制品业',
    '纺织业',
    '纺织服装、服饰业',
    '皮革、毛皮、羽毛及其制品和制鞋业',
    '木材加工和木、竹、藤、棕、草制品业',
    '家具制造业',
    '造纸和纸制品业',
    '印刷和记录媒介复制业',
    '文教、工美、体育和娱乐用品制造业',
    '石油加工、炼焦和核燃料加工业',
    '化学原料和化学制品制造业',
    '医药制造业',
    '化学纤维制造业',
    '橡胶和塑料制品业',
    '非金属矿物制品业',
    '黑色金属冶炼和压延加工业',
    '有色金属冶炼和压延加工业',
    '金属制品业',
    '通用设备制造业',
    '专用设备制造业',
    '汽车制造业',
    '铁路、船舶、航空航天和其他运输设备制造业',
    '电气机械和器材制造业',
    '计算机、通信和其他电子设备制造业',
    '仪器仪表制造业',
    '其他制造业',
    '废弃资源综合利用业',
    '金属制品、机械和设备修理业',
    '电力、热力生产和供应业',
    '燃气生产和供应业',
    '水的生产和供应业',
    '房屋建筑业',
    '土木工程建筑业',
    '建筑安装业',
    '建筑装饰和其他建筑业',
    '批发业',
    '零售业',
    '铁路运输业',
    '道路运输业',
    '水上运输业',
    '航空运输业',
    '管道运输业',
    '装卸搬运和运输代理业',
    '仓储业',
    '邮政业',
    '住宿业',
    '餐饮业',
    '电信、广播电视和卫星传输服务',
    '互联网和相关服务',
    '软件和信息技术服务业',
    '货币金融服务',
    '资本市场服务',
    '保险业',
    '其他金融业',
    '房地产业',
    '租赁业',
    '商务服务业',
    '研究和试验发展',
    '专业技术服务业',
    '科技推广和应用服务业',
    '水利管理业',
    '生态保护和环境治理业',
    '公共设施管理业',
    '居民服务业',
    '机动车、电子产品和日用产品修理业',
    '其他服务业',
    '教育',
    '卫生',
    '社会工作',
    '新闻和出版业',
    '广播、电视、电影和影视录音制作业',
    '文化艺术业',
    '体育',
    '娱乐业',
    '中国共产党机关',
    '国家机构',
    '人民政协、民主党派',
    '社会保障',
    '群众团体、社会团体和其他成员组织',
    '基层群众自治组织',
    '国际组织',
  ];
  /**
   * 风险颜色
   */
  color = {
    // 重大
    zd: '#FF0000',
    // 较大
    jd: '#FF8800',
    // 一般
    yb: '#FFFF00',
    // 低风险
    di: '#5abaff',
  };

  /**
   * 隐患颜色
   */
  hiddencolor = {
    // 重大
    zd: '#FF0000',

    // 一般
    yb: '#1890ff',
  };
  mangerType: any = [
    { label: '基础管理类', value: '0' },
    { label: '现场管理类', value: '1' },
  ];

  /**
   * 通过key获取node
   * @param treeData tree 数据源
   * @param key
   */
  getNodeByKey(treeData: any[], key: string) {
    let node: any = {};
    const recursion = arr => {
      for (const item of arr) {
        if (item.key == key) {
          node = item;
          break;
        }
        if (Array.isArray(item.children)) {
          recursion(item.children);
        }
      }
    };
    recursion(treeData);
    return node;
  }

  /**
   * 获取随机整数
   * @param lowValue
   * @param highValue
   */
  selectRandom(lowValue = 0, highValue = 1000) {
    const choice = highValue - lowValue + 1;
    return Math.floor(Math.random() * choice + lowValue);
  }

  /**
   * 获取地址列表
   * @param type
   * @param parentCode
   */
  getAddress(type = 'province', parentCode = '0') {
    if (parentCode == null) {
      return of([]);
    }

    const params = {
      name: type,
      parentCode,
    };
    return this.http
      .get<any>(`${environment.domain}/basics/v1/districts/data`, { params, headers: { token: '' } })
      .pipe(
        map(res => {
          if (res.code == 0) {
            return res.data || [];
          } else {
            return [];
          }
        }),
      );
  }
}
