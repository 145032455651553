import { NgModule } from '@angular/core';

import { SharedModule } from '@shared';
import { RouteRoutingModule } from './routes-routing.module';
// dashboard pages
import { DashboardComponent } from './dashboard/dashboard.component';
// passport pages
import { UserLoginComponent } from './passport/login/login.component';
import { UserRegisterComponent } from './passport/register/register.component';
import { UserRegisterResultComponent } from './passport/register-result/register-result.component';
// single pages
import { CallbackComponent } from './callback/callback.component';
import { UserLockComponent } from './passport/lock/lock.component';
// 监管中心
import { DataLsdJgComponent } from './data-lsd-jg/data-lsd-jg.component';
import { DataLsdVideoComponent } from './data-lsd-video/data-lsd-video.component';
import { DataLsdV2Component } from './data-lsd-v2/data-lsd-v2.component';
// 行政区划企业列表
import { LayoutQuotaComponent } from './quota/layout-quota/layout-quota.component';
import { CountsComponent } from './quota/count/counts.component';
import { CompaniesListComponent } from './quota/companies-list/companies-list.component';
import { LayoutQuotaGreatComponent } from './quota/layout-quota-great/layout-quota-great.component';
import { CompaniesGreatListComponent } from './quota/companies-great-list/companies-great-list.component';
// 风险，重大风险数量
import { LayoutRiskComponent } from './quota/layout-risk/layout-risk.component';
import { JtCompaniesListComponent } from './quota/jtcompanies-list/jtcompanies-list.component';
import { JtDangerComponent } from './quota/jtdanger/jtdanger.component';
import { JtRiskComponent } from './quota/jtrisk/jtrisk.component';
import { RiskComponent } from './quota/risk/risk.component';
// 隐患数量
import { DangerComponent } from './quota/danger/danger.component';
import { V2RiskComponent } from './v2quota/v2risk/v2risk.component';
// 隐患数量
import { V2DangerComponent } from './v2quota/danger/danger.component';
import { DataLsdJgfreeComponent } from './data-lsd-jgfree/data-lsd-jgfree.component';
// 集团公司安全管理信息中心
import { DataLsdJtComponent } from './data-lsd-jt/data-lsd-jt.component';
import { NoticeComponent } from './notice/notice.component';
import { EditComponent } from './notice/edit/edit.component';
import { DetailComponent } from './notice/detail/detail.component';
import { CompanyComponent } from './notice/company/company.component';
import { CheckBookDetailComponent } from './v2quota/danger/detail/detail.component';

// 集团企业列表
import { JtcountsComponent } from './quota/jtcount/jtcounts.component';

import { AmapFullComponent } from './dashboard/amap-full/amap-full.component';


const COMPONENTS = [
  DashboardComponent,
  UserLoginComponent,
  UserRegisterComponent,
  UserRegisterResultComponent,
  CallbackComponent,
  UserLockComponent,
  DataLsdJgComponent,
  DataLsdVideoComponent,
  DataLsdV2Component,
  CountsComponent,
  RiskComponent,
  DangerComponent,
  DataLsdJgfreeComponent,
  DataLsdJtComponent,
  NoticeComponent,
  V2RiskComponent,
  V2DangerComponent,
  JtcountsComponent,
  LayoutQuotaComponent, CompaniesListComponent, LayoutQuotaGreatComponent, CompaniesGreatListComponent,
  LayoutRiskComponent,
  JtCompaniesListComponent,
  JtDangerComponent,
  AmapFullComponent
];
const COMPONENTS_NOROUNT = [EditComponent, DetailComponent, CompanyComponent, CheckBookDetailComponent, JtDangerComponent, JtRiskComponent];

@NgModule({
  imports: [SharedModule, RouteRoutingModule],
  declarations: [...COMPONENTS, ...COMPONENTS_NOROUNT],
  entryComponents: COMPONENTS_NOROUNT,
})
export class RoutesModule { }
