import { Component, OnInit } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { HttpClient } from '@angular/common/http';
import { NzMessageService, NzModalRef } from 'ng-zorro-antd';
import { baseUrl, uploadUrl } from '@core';
import { CompanyComponent } from '../company/company.component';

// tslint:disable-next-line: class-name
export interface getFileData {
  code: number;
  data: getFileDataList[];
  msg: string;
}
// tslint:disable-next-line: class-name
export interface getFileDataList {
  fileName: string;
  fileUrl: string;
}

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less'],
})
export class EditComponent implements OnInit {
  uploadAction = uploadUrl;

  // 行的原始数据
  data: any = {};
  dept_nodes: any = [];
  // 当前选中的节点
  selectNode: any = {};
  areaData: any = {};
  basePar: any = {};
  companyData = [];

  isShow = true;
  // 编辑器初始化
  viewInitComplete = false;
  config = {
    // topOffset: 5
    autoHeightEnabled: false,
    initialFrameHeight: 600,
  };
  listOfOption = [
    {
      value: 1,
      label: '企业负责人',
    },
    {
      value: 2,
      label: '安全部门负责人',
    },
    {
      value: 3,
      label: '企业超级管理员',
    },
  ];

  ue = null;
  isLoading = false;
  insertimageLoading = false;
  inserthtmlLoading = false;
  constructor(
    private modal: NzModalRef,
    private mod: ModalHelper,
    public msg: NzMessageService,
    public http: _HttpClient,
    public ngHttp: HttpClient,
  ) {}

  ngOnInit() {
    this.data = {
      supervisionCenterScopeStatus: '1',
      publishMethod: '0',
    };
  }

  /**
   * 打开 选择企业 对话框
   */
  openCompany() {
    this.mod
      .create(
        CompanyComponent,
        { basePar: this.basePar, companyData: this.companyData },
        {
          size: 1400,
          modalOptions: {
            nzMaskClosable: false,
          },
        },
      )
      .subscribe(res => {
        if (res && res.length) {
          this.data.supervisionCenterScopeCompany = res.map(item => item.companyId);
          this.companyData = res;
          this.data.supervisionCenterScopeName = res[0].fullName + '等' + res.length + '家企业';
        }
      });
  }

  /**
   * 编辑器初始化
   */

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    setTimeout(() => {
      this.viewInitComplete = true;
    }, 0);
  }
  ready(ue) {
    console.log('UE 初始化完成!', ue);
    this.ue = ue;
  }
  htmlChange() {
    console.log(this.data.text);
  }

  /**
   * 监听发布范围变化
   */
  onChange($event: string[]): void {
    console.log($event);
  }

  /**
   * 保存
   */
  save() {
    if (this.data.supervisionCenterScopeStatus === '2') {
      if (!this.data.supervisionCenterScopeCompany || !this.data.supervisionCenterScopeCompany.length) {
        this.msg.error('请选择企业');
        return;
      }
    }
    if (!this.data.supervisionCenterScopePeople.length) {
      this.msg.error('请选择接收人');
      return;
    }
    if (!this.data.text) {
      this.msg.error('请输入正文!');
      return;
    }

    const formdata = {
      ...this.data,
      text: `<div style="padding: 24px;">${this.data.text}</div>`,
    };

    this.isLoading = true;
    this.http
      .post<any>(
        `${baseUrl}/hazard/v1/regulatoryscreens/notice/publish?code=${this.basePar.code}&areaLevel=${this.basePar.areaLevel}`,
        formdata,
      )
      .subscribe(res => {
        this.isLoading = false;
        if (res.code === 0) {
          this.msg.success(res.msg);
          this.modal.destroy(res);
        }
      });
  }

  close() {
    this.modal.destroy();
  }

  /**
   * 文件上传
   */
  getBeforeUpload(type) {
    return (file): boolean => {
      const formData = new FormData();
      formData.append('file', file);
      const authToken = JSON.parse(window.localStorage.getItem('UserInfo') || '{}').token;
      const headers = {
        token: authToken,
        Authorization: 'Bearer ' + authToken,
      };
      if (file.size / 1024 > 5120) {
        this.msg.error('文件不能大于5M!');
        return false;
      }
      this[`${type}Loading`] = true;
      this.ngHttp.post<getFileData>(`${baseUrl}/hazard/v1/regulatoryscreens/notice/file`, formData).subscribe(res => {
        this[`${type}Loading`] = false;
        if (res.code === 0) {
          const fileUrl = res.data[0].fileUrl;
          if (type === 'insertimage') {
            // 插入图片
            const notice_ueditor = document.getElementById('notice_ueditor');
            const notice_ueditor_width = notice_ueditor.getBoundingClientRect().width;

            // 检测图像大小
            const img = document.createElement('img');
            img.src = fileUrl;
            img.style.opacity = '0';
            img.style.zIndex = '-1';
            img.style.position = 'fixed';
            img.style.top = '0';
            img.style.left = '0';
            document.body.appendChild(img);
            img.onload = () => {
              const width = img.getBoundingClientRect().width;
              const height = img.getBoundingClientRect().height;
              let imgHtml = '';

              // tslint:disable-next-line: prefer-conditional-expression
              if (width >= notice_ueditor_width) {
                imgHtml = `<img style=" max-width: 100%; " src="${fileUrl}" width="${notice_ueditor_width - 50}" />`;
              } else {
                imgHtml = `<img style=" max-width: 100%; " src="${fileUrl}" />`;
              }
              this.ue.execCommand('inserthtml', imgHtml);
              document.body.removeChild(img);
            };
          } else {
            // 插入html
            this.ue.execCommand(
              'inserthtml',
              `<span>附件: <a href="${fileUrl}" onclick="return false;" target="_blank">${file.name}</a></span>`,
            );
          }
        } else {
          this.msg.error(res.msg || '文件上传不成功!');
        }
      });
      return false;
    };
  }
}
