import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzMessageService } from 'ng-zorro-antd';
import { baseUrl, CommonService } from '@core';
import { format } from 'date-fns';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map } from 'rxjs/operators';

export interface PersonData {
  code: number;
  data: PersonDataList;
  msg: string;
}
export interface PersonDataList {
  records: [];
  current: number;
  pages: number;
}
export interface HiddenData {
  code: string;
  areaLevel: string;
  matchingType: string;
}
@Component({
  selector: 'app-jtdanger',
  templateUrl: './jtdanger.component.html',
  styleUrls: ['./jtdanger.component.less'],
})
export class JtDangerComponent implements OnInit {
  station_nodes: any = [];
  position_nodes = [];
  personData = []; // 排查人
  @Input() Jttype = 0;
  @Input() year = format(new Date(), 'YYYY');
  companyId: any;
  baseUrl = baseUrl;

  /**
   * table
   */
  loading = false;
  params: any = {};
  searchParams: any = {
    checkResult: 0,
    Mtype: [],
  };
  rows = [];
  pageIndex = 1;
  total = 0;
  pageSize = 10;
  // 地区数据
  areaData = [];
  title = '隐患列表';

  width = '';
  widthList = [];

  widthMap = {
    '1': '50+150+150+150+150+150+150+150+150+150+150+150+150+150',
  };
  constructor(
    private http: _HttpClient,
    private msg: NzMessageService,
    private modal: ModalHelper,
    public commonService: CommonService,
  ) { }

  ngOnInit() {
    this.getData();
  }
  getWidth() {
    const widthList = this.widthMap[1].split('+');
    return `${widthList.reduce((prev, current) => prev + +current, 0)}px`;
  }

  getWidthList() {
    const widthList = this.widthMap[1].split('+');
    return widthList.map(w => `${w}px`);
  }

  /**
   * 获取隐患数据
   * @param reset
   */
  getData(reset: boolean = false) {
    this.width = this.getWidth();
    this.widthList = this.getWidthList();
    if (reset) {
      this.pageIndex = 1;
    }

    if (this.searchParams.searchTime && this.searchParams.searchTime.length > 0) {
      this.searchParams.checkStartTime = format(this.searchParams.searchTime[0], 'YYYY-MM-DD');
      this.searchParams.checkEndTime = format(this.searchParams.searchTime[1], 'YYYY-MM-DD');
    } else {
      this.searchParams.checkStartTime = '';
      this.searchParams.checkEndTime = '';
    }
    if (this.searchParams.reformTime && this.searchParams.reformTime.length > 0) {
      this.searchParams.reformStartTime = format(this.searchParams.reformTime[0], 'YYYY-MM-DD');
      this.searchParams.reformEndTime = format(this.searchParams.reformTime[1], 'YYYY-MM-DD');
    } else {
      this.searchParams.reformStartTime = '';
      this.searchParams.reformEndTime = '';
    }
    if (this.searchParams.potentialAddUserBranch == null) {
      this.searchParams.potentialAddUserBranch = '';
    }
    if (this.searchParams.checkResult == null) {
      this.searchParams.checkResult = '0';
    }
    if (this.searchParams.potentialDepartmentCode == null) {
      this.searchParams.potentialDepartmentCode = '';
    }
    if (this.searchParams.potentialStatus == null) {
      this.searchParams.potentialStatus = '';
    }
    if (this.searchParams.potentialLocationCode == null) {
      this.searchParams.potentialLocationCode = '';
    }

    const searchParams = {
      ...this.searchParams,
      company: this.companyId,
      type: this.Jttype,
      year: this.year,
      page: this.pageIndex,
      limit: this.pageSize,
    };
    Object.entries(searchParams).forEach(([key, value]) => {
      value === null && delete searchParams[key];
    });
    this.loading = true;
    this.http
      .get<any>(`${baseUrl}/hazard/v1/regulatoryscreens/company/hiddenTrouble/detail/list`, searchParams)
      .subscribe(res => {
        this.loading = false;
        if (res.code == 0) {
          this.rows = res.data.content || [];
          this.rows.forEach(item => {
            if (item.reformTimeLimit && item.potentialStatus == '1') {
              const rtime = new Date(item.reformTimeLimit).getTime();
              const ntime = new Date().getTime();
              if (rtime < ntime) {
                item.color = 'red';
              }
            }
          });
          this.total = res.data.totalElements || 0;
        } else {
          this.msg.error(res.msg || '加载失败');
        }
      });
  }

  // 单个文件下载并改名字
  onStart(e) {
    this.http
      .get('https://jtghmin.xinanyun.cn/jtgh/' + e.potentialInspectInfo.reformNoticeFile.urlPath, this.searchParams, {
        responseType: 'blob',
        observe: 'response',
      })
      .subscribe((res: any) => {
        if (res.body.code == 1) {
          this.loading = false;
          this.msg.error('导出不成功!');
          return;
        }

        try {
          const link = document.createElement('a');
          const blob = new Blob([<any>res.body]);
          let fileNmae = e.potentialInspectInfo.reformNoticeFile.fileName;
          try {
            fileNmae = decodeURIComponent(res.headers.get('Content-Disposition').split('filename=')[1]);
          } catch (error) { } //在有的浏览器中取不到响应头
          link.setAttribute('href', window.URL.createObjectURL(blob));
          link.setAttribute('download', fileNmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          this.msg.error(error.toString());
        }
      });
  }
  time(e) {
    if (JSON.stringify(e) == '[]') {
      this.searchParams.searchTime = null;
      this.searchParams.checkStartTime = '';
      this.searchParams.checkEndTime = '';
    } else {
      this.searchParams.checkStartTime = format(this.searchParams.searchTime[0], 'YYYY-MM-DD');
      this.searchParams.checkEndTime = format(this.searchParams.searchTime[1], 'YYYY-MM-DD');
    }
  }
  reformtime(e) {
    if (JSON.stringify(e) == '[]') {
      this.searchParams.reformTime = null;
      this.searchParams.reformStartTime = '';
      this.searchParams.reformEndTime = '';
    } else {
      this.searchParams.reformStartTime = format(this.searchParams.reformTime[0], 'YYYY-MM-DD');
      this.searchParams.reformEndTime = format(this.searchParams.reformTime[1], 'YYYY-MM-DD');
    }
  }
}
