import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { NzDrawerRef, NzDrawerService, NzDrawerModule, NzMessageService } from 'ng-zorro-antd';
import { HttpParams } from '@angular/common/http';
import { baseUrl, CommonService } from '@core';

import { format } from 'date-fns';
import { ActivatedRoute, Router } from '@angular/router';
import { JtDangerComponent } from '../jtdanger/jtdanger.component';
export interface HiddenData {
  code: string;
  areaLevel: string;
  matchingType: string;
}
@Component({
  selector: 'app-danger',
  templateUrl: './danger.component.html',
  styleUrls: ['./danger.component.less'],
})
export class DangerComponent implements OnInit {
  baseUrl = baseUrl;
  dep_nodes = [];
  /**
   * table
   */
  loading = false;
  aloading = false;
  params: any = {};
  searchParams: any = {
    Mtype: [],
  };
  rows = [];
  pageIndex = 1;
  total = 0;
  pageSize = 10;
  // 地区数据
  areaData = [];
  modelTitle = '本年隐患列表';
  constructor(
    private http: _HttpClient,
    private msg: NzMessageService,
    private modal: ModalHelper,
    private router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  ngOnInit() {
    switch (this.params.type) {
      case '0':
        this.modelTitle = '本年隐患列表';
        break;
      case '1':
        this.modelTitle = '未整改隐患列表';
        break;
      case '2':
        this.modelTitle = '本月新增隐患列表';
        break;
      case '3':
        this.modelTitle = '本月新增重大隐患列表';
        break;
    }
    this.getAreaData();
    this.search();
  }
  // 获取地区选择
  getAreaData() {
    const params = {
      ...this.params,
    };
    this.aloading = true;
    this.http.get<any>(`${baseUrl}/hazard/v1/regulatoryscreens/riskRanking`, params).subscribe(res => {
      this.aloading = false;
      if (res.code === 0) {
        console.log(res.data.riskRanking);
        this.areaData = res.data.riskRanking;
        this.areaData = this.areaData.slice(1);
      } else if (res.code === 100) {
        this.router.navigateByUrl('/passport/login');
        return;
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }
  /**
   * 加载数据
   * @param reset 参数
   */
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    if (this.searchParams.accTime && this.searchParams.accTime.length > 0) {
      this.searchParams.checkStart = format(this.searchParams.accTime[0], 'YYYY-MM-DD');
      this.searchParams.checkEnd = format(this.searchParams.accTime[1], 'YYYY-MM-DD');
    } else {
      this.searchParams.checkStart = '';
      this.searchParams.checkEnd = '';
    }
    if (this.searchParams.region === null) {
      this.searchParams.region = '';
    }
    if (this.searchParams.industry === null) {
      this.searchParams.industry = '';
    }
    if (this.searchParams.Mtype && this.searchParams.Mtype.length > 0) {
      this.searchParams.basis = this.searchParams.Mtype.join(',');
    } else {
      this.searchParams.basis = '';
    }

    const searchParams = {
      ...this.params,
      ...this.searchParams,
      page: this.pageIndex,
      limit: this.pageSize,
    };
    this.loading = true;
    this.http.get<any>(`${baseUrl}/hazard/v1/regulatoryscreens/popup/hiddenTrouble`, searchParams).subscribe(res => {
      this.loading = false;
      if (res.code === 0) {
        this.rows = res.data.content || [];
        this.total = res.data.totalElements || 0;
      } else {
        this.msg.error(res.msg || '加载失败');
      }
    });
  }
  /**
   * 打开隐患数量弹框
   */
  openDanger(companyId, type) {
    this.modal
      .create(
        JtDangerComponent,
        { companyId, Jttype: type, year: this.params.year },
        {
          size: 1200,
          modalOptions: {
            nzMaskClosable: false,
          },
        },
      )
      .subscribe(() => {
        this.search();
      });
  }
}
