import { Component, OnInit } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-layout-risk',
  templateUrl: './layout-risk.component.html',
  styles: []
})
export class LayoutRiskComponent implements OnInit {
  title = '企业风险统计';
  params: any = {};
  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  ngOnInit() {
    if(this.params.type == 1){
      this.title = '企业重大风险统计'
    }else{
      this.title = '企业风险统计'
    }
  }

}
